@import 'assets/scss/variables.module';

$overlay-color: rgba(0, 0, 0, 0.4);
$close-button-color: $color;

.container {
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;

  color: $color;
  background-color: $overlay-color;

  display: flex;
  justify-content: center;

  padding-top: 5rem;

  @include response-small {
    padding-top: 1rem;
  }
}

.modal {
  position: relative;

  background: $background-color;
  border-radius: 5px;
  border: 1px solid $light-color2;
  box-shadow: $generic-box-shadow;
  padding: 1rem;

  width: 65%;
  max-width: 1200px;

  height: fit-content;
  max-height: 85%;
  overflow-y: auto;
  overflow-x: hidden;

  @include response-small {
    max-width: 90%;
    min-width: 90%;
  }
}

.closeButton {
  position: absolute;
  top: 9px;
  right: 1px;
  color: $close-button-color;
  background: transparent;
  border: none;

  transition: filter 0.2s ease;

  &:hover {
    cursor: pointer;
    filter: brightness(0.75);
  }
}
