@import 'assets/scss/variables.module';

.container {
  position: relative;

  height: 311px;
  min-height: 311px;

  aspect-ratio: 5 / 7;

  border-radius: 15px;
  overflow: hidden;

  border: 2px solid $light-color2;
}

.border {
  border: 1px solid $card-border;
  background-color: $card-border;
}

.loadingCard {
  position: absolute !important;

  top: 0;
  left: 0;

  transition: opacity 0.3s ease-in-out;
}

.image {
  position: absolute;

  // border-radius: 1rem;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  opacity: 1;

  transition: opacity 0.3s ease-in-out;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
