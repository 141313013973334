@import 'assets/scss/variables.module';

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem;

  background-color: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;
}

.options {
  flex-wrap: wrap;

  & > * {
    max-width: 325px;
  }
}
