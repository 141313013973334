@import 'assets/scss/variables.module';

.common-container {
  width: 100%;
  position: relative;

  padding: 1rem;

  border-radius: 3px;
  background-color: $light-background;
  border: 1px solid $input-border-color;

  display: flex;
  justify-content: center;
  gap: 0.5rem;

  min-height: 500px;

  transition: opacity 0.2s ease-in-out; // This is for the fading in effect since some layouts still use JS to measure the width of the container prior to the inital render

  @include response-small {
    flex-wrap: wrap;
  }
}
