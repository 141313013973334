@import 'assets/scss/variables.module';

.card {
  position: relative;
  width: 100%;
}

.cardButton {
  width: 100%;

  position: relative;
  padding: 0;

  background: transparent;
  border: none;
  border-radius: 15px;

  overflow: hidden;

  transition: box-shadow 0.1s ease, transform 0.2s ease;

  &:active {
    animation: pulse 0.2s 1 ease-out;
  }

  &:hover:enabled {
    cursor: pointer;

    transform: scale(1.01);
    box-shadow: $generic-box-shadow;
  }
}

.basicCard {
  width: 100%;
  min-width: unset;

  height: auto;
  min-height: unset;

  &:hover {
    cursor: pointer !important;
  }
}

.cardImage {
  overflow: hidden;
  position: unset;
}

.actions {
  @include flex-even;

  margin-top: -5px;
  margin-bottom: 0;
}

.add {
  background: transparent;
  border: none;

  color: $color;

  transition: transform 0.2s ease-in-out;

  &:hover {
    cursor: pointer;

    transform: scale(1.1);
  }
}

.dropdown {
  width: 180px;

  z-index: 4;
}

body:global(.left-hand-controls) .dropdown {
  right: unset;
  left: 0;
}

.dropdownIcon {
  margin-top: 1rem;
}

.controls {
  position: absolute;
  right: -10px;
  top: 3rem;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @include response-small {
    top: 2rem;
  }
}

body:global(.left-hand-controls) .controls {
  right: unset;
  left: -10px;
}

.button {
  background: $background-color;

  color: $color;
  font-weight: bold;
  border: 1px solid $light-color;

  width: 35px;
  height: 35px;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 1px 2px 2px 0 rgb(0 0 0 / 50%);

  i {
    margin: 0;
    height: unset;
    width: unset;
    transition: box-shadow, transform 0.2s ease-in-out;
  }

  transition: all 0.2s ease-in-out;

  &:hover:enabled {
    cursor: pointer;
    box-shadow: 1px 2px 2px 0 rgb(0 0 0 / 75%);
    i {
      transform: scale(1.1);
    }
    transform: scale(1.1);
  }

  &:disabled {
    border: 1px solid $light-color;
    cursor: default;
    box-shadow: none;
    color: $light-color2;
    opacity: 1;
  }
}

.green {
  z-index: 1;
  color: $card-button-green;
  border: 1px solid $card-button-green;
}

.blue {
  color: $card-button-blue;
  border: 1px solid $card-button-blue;
}
.red {
  color: $card-button-red;
  border: 1px solid $card-button-red;
  &:disabled {
    transform: translateY(-77px);
  }
}

.quantity {
  transform: translateY(-38px);
}

.buttonContainer {
  height: 35px;
  width: 35px;
  position: relative;
}

.hasQuantity {
  transform: translateY(0);
  opacity: 1 !important;
  box-shadow: none;
  color: #fff !important;
  background: $quantity-green; // Trying to make this stand out more, I hate colors
}

.hasOtherPrintingQuantity {
  color: #fff;
  background: $quantity-blue;
  border: 1px solid $light-color;
  box-shadow: inset 0 0 100px 100px rgb(255 255 255 / 20%);
  font-weight: bold;
  white-space: nowrap;

  position: absolute;
  right: -9px;
  top: -6px;

  border-radius: 50%;
  width: 22px;
  height: 22px;

  padding: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: width 0.2s ease-in-out, height 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  cursor: pointer;

  font-size: 12px;

  &:focus {
    width: 200px;
    height: unset;

    border-radius: unset;
    white-space: normal;
  }

  &:focus::before {
    content: 'This shows the copies of other printings in this deck: ';
  }
}

.flipButton {
  position: absolute;
  top: 80px;
  left: -5px;

  transform: rotate(45deg);
  transition: 0.2s transform ease;

  background: #6435c9; // ripping off semantic purple here
  color: white;
}

body:global(.left-hand-controls) .flipButton .flipped {
  transform: rotate(0) !important;
}

body:global(.left-hand-controls) .flipButton {
  left: unset;
  right: -5px;
}

.moveCategoryExtras {
  max-height: 300px;
  overflow: auto;
}
