.contextMenu {
  width: 225px;
}

.externalLinks {
  padding-left: 25px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.categoryMenu {
  overflow-y: auto;
  max-height: 300px;
}
