@import 'assets/scss/variables.module.scss';

.splotch {
  height: 10px;
  width: 10px;
  display: inline-block;
}

.tagName {
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

// Remove hover effect
.headerContainer {
  font-weight: bold;

  &:hover {
    background-color: unset !important;
  }
}

.none {
  display: flex;
  align-items: center;
  color: $light-color;
  font-size: 10px;
}
