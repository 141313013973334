@import 'assets/scss/variables.module';

.loader {
  border-style: solid;

  border-radius: 50%;

  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.center {
  @include flex-center;

  flex-direction: column;

  height: 100%;

  label {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 16px;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
