.hoverCard {
  position: fixed;

  // Allow the position to be configured via the cursor's coordinates
  top: 0;
  left: 0;

  display: none;

  cursor: pointer;

  z-index: 8;

  transition: opacity 0.3s ease, tranform 0.2s ease;
}
