@import 'assets/scss/variables.module.scss';

.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem 0;

  label {
    user-select: none;
    color: $color;
    flex: 1;
    padding-left: 0.5em;
  }

  transition: filter 0.2s ease-in-out;

  &:hover,
  label:hover,
  input:hover {
    cursor: pointer;
  }
}

.hover {
  &:hover,
  label:hover,
  input:hover {
    filter: brightness(1.3);
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
