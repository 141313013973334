@import 'assets/scss/variables.module';

.container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.controls {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;

  @include response-medium-small {
    flex-wrap: wrap;
  }
}
