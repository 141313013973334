@import 'assets/scss/variables.module';

.container {
  display: relative;
}

body:global(.dark-mode) .edhrecLogo {
  height: 15px;
  filter: invert(1);
}

.tabContainer {
  justify-content: flex-start;
  width: 100%;

  border-bottom: 3px solid $light-background;

  & > * {
    flex: unset;
  }

  @include response-small {
    flex-wrap: unset;
  }
}

.tabButton {
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  margin: -3px 0;
  padding: 0.75rem 1rem;

  i {
    margin: 0 !important;
  }

  @include response-small {
    font-size: 12px;

    padding: 0.75rem 0.5rem;
  }

  @include response-extreme-small {
    font-size: 10px;
  }
}

.customIconContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;
}

.spellbookLogo {
  height: 20px;
  margin-right: 0 !important;

  filter: grayscale(100%) brightness(0.35);
}

body:global(.dark-mode) .spellbookLogo {
  filter: grayscale(100%) brightness(2);
}
