@import 'assets/scss/variables.module';

$transition: opacity cubic-bezier(0.5, 0, 0.5, 1) 400ms;
$content-transition: opacity cubic-bezier(0.5, 0, 0.5, 1) 200ms;

.overlay {
  color: $color;

  will-change: opacity transform;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 7;

  transition: $transition;

  opacity: 0;
  pointer-events: none;
}

.content {
  will-change: opacity;

  transition: $content-transition;
  padding: 65px;
  padding-bottom: 200px;

  overflow-y: auto;
  height: 100%;

  opacity: 0;
  pointer-events: none;

  overscroll-behavior: contain;

  @include response-small {
    padding: 1rem;
    padding-bottom: 200px;
  }
}

body:global(.mediavine-ads-enabled) .content {
  padding-bottom: 150px;
}

.primaryContent {
  margin: auto;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.rootOverlay {
  background-color: $overlay-background-color;
}

.visable {
  opacity: 1; // Makes the overlay visible
  pointer-events: all;
}

.inactive {
  opacity: 0;
  pointer-events: none;
}

.grid {
  @include grid;
  position: fixed;

  top: 0;
}

.backButtonContainer {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;

  @include response-small {
    top: unset;
    bottom: $fix-mobile-ad-height;

    padding: 0 1rem;

    height: 50px;
    background-color: $framing-color;
    width: 100%;

    z-index: 3;
  }
}

body:global(.ad-free) .backButtonContainer {
  @include response-small {
    bottom: 0 !important;
  }
}

.backButtonContent {
  height: 100%;

  @include response-small {
    display: grid;
    justify-content: space-between;
    align-items: center;

    grid-template-columns: 25% 75%; // maybe want to make this overwrittable if we ever want more than 4 buttons
  }
}

.mobileControls {
  display: none;

  @include response-small {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.back {
  background-color: transparent;
  color: $color;
  border: none;

  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  i {
    font-size: 2rem;
    margin: 0 !important;
  }

  span {
    font-size: 0.8rem;
  }

  &:hover {
    cursor: pointer;
  }

  @include response-small {
    @include mobile-toolbar-button;
  }
}

.weirdCloseContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
