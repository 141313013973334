$size: 38px;

.container {
  position: absolute;
  top: 0;
  left: 0;

  width: $size;
  height: $size;

  & > div:first-child {
    width: $size;
    height: $size;
  }
}

.cornerQuantity {
  border-radius: 11px 0px 0px 0px !important;
  border: none;
  overflow: hidden;

  font-weight: bold !important;
  user-select: none;

  clip-path: polygon(0 0, 0% 100%, 100% 0);

  display: flex;
  padding: 5px 0 0 3px;

  width: 100%;
  height: 100%;

  font-size: 12px;

  &:hover:enabled {
    cursor: pointer;
  }
}

.menu {
  top: calc($size / 1.15);
  left: 0;

  width: 165px;
  max-height: 250px;

  overflow-y: auto;

  z-index: 1;
}
