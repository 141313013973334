@import 'assets/scss/variables.module';

.container {
  color: $color;

  text-align: center;

  p {
    font-size: 16px;
  }

  a {
    font-weight: bold;
    color: $archidekt-orange;
    transition: filter 0.2s ease;

    &:hover {
      cursor: pointer;
      filter: brightness(1.1);
    }
  }
}

.branding {
  color: $color;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;

  img {
    width: 100px;
    margin-bottom: 10px;
  }
}

body:global(.dark-mode) .branding {
  img {
    filter: invert(1);
  }
}
