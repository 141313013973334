@import 'assets/scss/variables.module.scss';

.outerContainer {
  margin-top: 20px;
  position: relative;
  width: 400px;
  height: 80px;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.25s;

  @include response-medium-small {
    width: 250px;
  }
}

.toastContainer {
  color: $color;
  border: 1px solid $light-color2;
  border-radius: 8px;
  background-color: $light-background;
  width: 400px;
  position: absolute;
  height: 80px;
  padding: 7px;
  display: flex;
  right: 0;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 3px 7px -1px rgba(0, 0, 0, 0.5);
  transition: right 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  i {
    font-size: 2em;
  }
  @include response-medium-small {
    width: 250px;
  }
}

.centerContainer {
  width: 300px;
  height: 100%;
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  justify-content: center;
  @include response-medium-small {
    width: 150px;
  }
}

.title {
  color: $color;
  margin-bottom: 5px;
}

.text {
  color: $color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.error {
  border-left: 5px solid #e12828;
  i {
    color: #e12828;
  }
}

.warning {
  border-left: 5px solid #e1bc28;
  i {
    color: #e1bc28;
  }
}

.removeButton {
  background-color: transparent;
  border: none;
  i {
    color: $light-color;
    font-size: 1.5em;
  }
  &:hover:enabled {
    cursor: pointer;
  }
}

.viewButton {
  margin-top: 5px;
  width: max-content;
  padding: 0;
  background-color: transparent;
  border: none;
  color: $link-color;
  &:hover:enabled {
    cursor: pointer;
  }
}

.offscreen {
  right: -425px;
}

.outerOffscreen {
  height: 0;
  margin-top: 0;
}
