@import 'assets/scss/variables.module';
@import '../shared.module.scss';

.hoverTransition {
  transition: box-shadow 0.05s linear, transform 0.2s ease-in-out;

  &:hover:enabled {
    cursor: pointer;

    transition-delay: 0.1s;

    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    transform: scale(1.05);
  }
}

.container {
  @include searchTab;

  max-width: $x-large !important;

  overflow: auto;
  height: 100%;

  padding: 2rem 77px !important;

  @include response-small {
    padding: 1rem !important;
    padding-bottom: 75px !important;
  }
}

.header {
  max-width: 800px;
  width: 100%;

  margin-bottom: 2rem;
}

.tabGrid {
  display: grid;
  justify-items: center;
  justify-content: center;
  align-items: center;

  gap: 25px;

  grid-template-columns: repeat(3, 1fr);

  @include response-medium-small {
    grid-template-columns: repeat(2, 1fr);
  }

  @include response-small {
    grid-template-columns: repeat(1, 1fr);
  }
}

body:global(.search-v2-locked) .tabGrid {
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

  max-width: 800px;

  width: 100%;
}

.preview {
  position: relative;
  max-width: 250px;
}

.killButton {
  position: absolute;
  top: -1rem;
  right: -1rem;

  z-index: 1;

  height: 2rem;
  width: 2rem;

  border-radius: 2rem;
  border: none;

  font-size: 0.75em;
  text-align: center;

  background-color: #ba5b54;
  color: white;

  @extend .hoverTransition;

  i {
    margin: 0 !important;
  }

  &:hover:enabled {
    cursor: pointer;
  }
}

.back {
  @include absolute-position-buttons;

  top: 1rem;
  left: 0;
}

.history {
  @include absolute-position-buttons;

  top: 1rem;
  right: 0;
}

.newTab {
  @include mobile-toolbar-button;
}
