@import 'assets/scss/variables.module';

.content {
  color: $color;

  padding: 1rem;

  background-color: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;

  min-height: 200px;
}

.ruling {
  padding-bottom: 1rem;
}

.comment {
  font-size: 16px;
}

.date {
  display: flex;
  justify-content: flex-end;

  color: $light-color;
}

.noRulings {
  font-size: 16px;
  color: $light-color;
  text-align: center;
  font-weight: bold;
  padding: 1rem 0;
}
