@import 'assets/scss/variables.module';

.container {
  display: flex;
  gap: 8px;

  label {
    font-weight: 16px;
    font-weight: bold;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    &:hover {
      cursor: pointer;
    }
  }

  @include response-small {
    margin-bottom: 1rem;
  }
}
