@mixin grid {
  position: absolute;
  top: 0;
  right: 0;

  z-index: -1;

  height: 100%;
  width: 100%;

  background-color: transparent;

  pointer-events: none;

  $line-color: rgb(150, 150, 150, 0.1);

  background-image: repeating-linear-gradient(0deg, $line-color, $line-color 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(90deg, $line-color, $line-color 1px, transparent 1px, transparent 10px);
  mask-image: linear-gradient(
    145deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 26%,
    rgba(255, 255, 255, 0) 70%,
    rgba(0, 0, 0, 1) 100%
  );
  -webkit-mask-image: linear-gradient(
    145deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 26%,
    rgba(255, 255, 255, 0) 70%,
    rgba(0, 0, 0, 1) 100%
  );
}
