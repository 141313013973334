@import 'assets/scss/variables.module.scss';

.container {
  position: fixed;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $color;

  position: 'fixed';

  height: 40px;
  width: 100%;

  z-index: 7; // explictly above the top drop zones
  pointer-events: none;

  transition: opacity 0.2s ease-in-out;

  opacity: 0;
}

.bottom {
  top: unset;
  bottom: 0;
}

.isDragging {
  background-color: $overlay-background-color;
  pointer-events: unset;

  opacity: 1;
}
