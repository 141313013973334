@import 'assets/scss/variables.module';

.trigger {
  width: 100%;
  text-align: left;

  background: transparent;
  border: none;

  font-weight: bold;
  color: $color;

  &:hover {
    cursor: pointer;
  }
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(90deg);
}
