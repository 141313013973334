@import 'assets/scss/variables.module.scss';

$border-radius: 5px;

.container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  gap: 1rem;

  label {
    position: absolute;

    top: -1.5rem;

    font-size: 0.85rem;
    font-weight: bold;
  }
}

.buttons {
  display: flex;
}

.button {
  padding: 0.5rem 0.75rem;

  border: solid 1px $light-color2;
  background-color: $light-background;

  transition: background-color 0.2s ease-in-out;

  i {
    margin: 0 !important; // fuck semantic
  }

  &:hover:enabled,
  &:focus:enabled {
    cursor: pointer;
    background-color: $light-background2;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.decrease {
  @extend .button;

  color: $button-red;

  border-radius: $border-radius 0 0 $border-radius;
}

.increase {
  @extend .button;

  color: $button-green;

  border-radius: 0 $border-radius $border-radius 0;
}

.input {
  max-width: 125px;
}
