@import 'assets/scss/variables.module';

.menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
}

.input {
  width: 100%;
  height: 100%;
  padding: 0 13px;
  border: 1px solid $light-color;
  border-radius: 5px;
  background: $light-background;

  color: $color;

  &:focus-within,
  &:focus {
    outline: none !important;
    border: 1px solid $archidekt-orange;
    box-shadow: 0px 0px 10px $archidekt-orange;
  }

  &:disabled {
    opacity: 0.4;
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    opacity: 1;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $secondary-text-color;
  }
}

.disabled {
  opacity: 0.4 !important;
}

.container {
  position: relative;
  height: 100%;
}

.chevron {
  position: absolute;
  margin: auto !important; // fuck you semantic
  top: 0;
  bottom: 0;
  right: 5px;
  pointer-events: none;
}

.loader {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 5px;
  pointer-events: none;
}

.prefixIcon {
  position: absolute;
  margin: auto !important;
  top: 0;
  bottom: 4px;
  left: 1rem;

  color: $archidekt-orange;
}

.prefixIconPadding {
  padding-left: 2.5rem !important;
}

.label {
  font-weight: bold;
}
