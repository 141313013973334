@import 'assets/scss/variables.module';

$avatar-size: 35px;
$notification-badge-size: 8px;

$new-notifcation-counter: red;
$new-notification-badge: lightblue;

.container {
  position: relative;
}

.counter {
  border-radius: 3px;
  background-color: $new-notifcation-counter;
  color: white;
  font-size: 75%;
  position: absolute;
  right: -4px;
  bottom: -5px;
  padding-left: 3px;
  padding-right: 4px;
  z-index: 1;
}

.menu {
  width: 265px;

  z-index: 2;

  height: 350px;
  overflow: auto;

  top: 125%;
  left: -125px;
}

.notification {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  transition: color 0.2s ease;

  &:hover {
    color: $archidekt-orange;
  }
}

.avatar {
  height: $avatar-size;
  width: $avatar-size;
  border-radius: 50%;
  border: 1px solid $light-color2;
}

.info {
  padding-left: 5px;
  font-weight: normal;
  position: relative;
  width: 100%;
}

.meta {
  color: $light-color;
  font-size: 12px;
}

.newNotificationBadge {
  height: $notification-badge-size;
  width: $notification-badge-size;
  border-radius: 50%;
  position: absolute;
  background: $new-notification-badge;
  top: 0;
  right: 0;
  animation: breathing 1.5s ease-in-out infinite normal;
}

@keyframes breathing {
  0% {
    filter: brightness(1);
  }

  50% {
    filter: brightness(1.4);
  }

  100% {
    filter: brightness(1);
  }
}
