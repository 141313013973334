@import 'assets/scss/variables.module';

.cardFilter {
  position: absolute;
  top: 0;
  left: 0;

  border-radius: 12px;

  pointer-events: none;

  aspect-ratio: 5/7;

  width: 100%;
}

.foil {
  background: linear-gradient(-60deg, purple, blue, green, yellow, red);
  opacity: 0.15;
}

.error {
  border: 10px solid red;
}

.secondaryCategory {
  background: white;
  opacity: 0.5;
}

.multiSelected {
  background-color: $archidekt-orange;
  opacity: 0.5;

  transition: opacity 0.2s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
}

.multiSelectedOutline {
  border: 3px solid $archidekt-orange;
  border-radius: 10px;
}

.multiSelectedCheck {
  color: white;

  font-size: 6rem !important;
  margin-right: 0 !important;
}

.filteredOut {
  border-radius: 10px;

  background: $background-color;
  opacity: 0.85;
  border: $light-background2;
  z-index: 1;

  transition: opacity 0.2s ease-in-out;
  transition-delay: 0.15s;
}
