@import 'assets/scss/variables.module';

.menu {
  width: 200px;
  z-index: 2;
  left: 0;
  top: 125%;

  button {
    height: 34px;
    padding: 0.5rem;
    padding-left: 15px;

    transition: color 0.2s ease;
  }
}

.trigger {
  height: 22px;
}
