.controls {
  display: flex;
  gap: 0.5rem;

  margin-bottom: 1rem;
}

.combos {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dropdown {
  width: 100%;

  & > div {
    width: 100%;
  }
}

.noCombos {
  display: flex;
  justify-content: center;

  margin: 1rem 0;

  font-size: 1.5rem;
  font-weight: bold;
}
