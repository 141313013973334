@import 'assets/scss/variables.module';

@keyframes loader-animation {
  0% {
    left: -100%;
  }
  49% {
    left: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

.container {
  height: 5px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.container .bar {
  position: relative;
  height: 5px;
  width: 100%;
  background-color: $archidekt-orange;
  animation-name: loader-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
