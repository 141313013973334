@import 'assets/scss/variables.module';

.container {
  outline: none;
  // We're absolutely positioned within our stack
  // NOTE - this can only be used within a stack because of this
  // TODO - should we move this style to the parent and pass it down so that this component can be used elsewhere? (probably)
  position: absolute;

  overflow: hidden;

  top: 0;
  left: 0;

  display: flex;
  width: 223px;
  height: 335px;

  transition: transform 0.4s ease;
  transition-delay: 0.1s;

  &:hover ~ .container {
    transform: translateY(300px);
  }
}

.imageCard {
  position: relative;

  width: 223px;
  height: 311px;
}

.hideControlsWithoutHovered {
  .controls,
  .extras {
    display: none;
  }

  &:hover,
  &:focus-within {
    .controls,
    .extras {
      display: flex;
    }
  }
}

.controls {
  display: flex;
  flex-direction: column;

  position: absolute;
  right: 1px; // so it doesn't clip into the border
  top: 15%;

  button {
    background-color: rgb(249, 250, 251, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px 0px 0px 5px;

    text-shadow: rgb(0 0 0) 2px 2px 8px;
    color: #fff;
    font-weight: bold;

    padding: 10px 8px;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      cursor: pointer;

      background-color: rgb(249, 250, 251, 0.3);
    }
  }
}

body:global(.left-hand-controls) .controls {
  left: 0;
  right: unset;

  button {
    border-radius: 0 5px 5px 0;
  }

  .increaseButton {
    border-radius: 0 5px 0 0 !important;
  }

  .decreaseButton {
    border-radius: 0 0 5px 0 !important;
  }
}

.filpButtonContainer {
  @extend .controls;

  right: unset;
  left: 1px;

  button {
    border-radius: 0 5px 5px 0;
  }
}

body:global(.left-hand-controls) .filpButtonContainer {
  left: unset;
  right: -1px;

  button {
    border-radius: 5px 0 0 5px;
  }
}

.cardDetailsButton {
  margin-top: 5px;
}

.increaseButton {
  border-radius: 5px 0 0 0 !important;
}

.decreaseButton {
  border-radius: 0 0 0 5px !important;
}

.priceBackground {
  background-color: $light-background;
}

.extrasContainer {
  min-height: 25px;
}

.extras {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.extrasMenu {
  width: 215px;
}

.extrasTrigger {
  background-color: transparent;
  border: none;
  color: $color;

  i {
    margin: 0 !important;
  }

  &:hover:enabled {
    cursor: pointer;
  }
}

.cardLoader {
  pointer-events: none;
}

.extrasContainer {
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0.15s;
}
