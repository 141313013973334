.toastListContainer {
  position: fixed;
  bottom: 25px;
  right: 0;
  margin: 25px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 2000;
}

body:global(.mediavine-ads-enabled) .toastListContainer {
  bottom: 200px;
}
