@import 'assets/scss/variables.module';

.bar1,
.bar2,
.bar3 {
  width: 20px;
  height: 2px;
  background-color: $color;
  margin: 4px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 6px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -6px) rotate(45deg);
}
