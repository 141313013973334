@import 'assets/scss/variables.module';

$font-color: #e3e3e3;
$non-sticky-bar-color: #181818;

.bar {
  @include sticky-wide-table-page;

  background-color: $framing-color;
  width: 100%;

  display: flex;
  justify-content: center;

  top: 0 !important;
  left: 0 !important;

  padding: 0 2rem;
  position: relative;
  z-index: 7;

  @include response-small {
    top: unset !important;

    padding: 0 1rem;
  }
}

.unsticky {
  position: relative;
}

.content {
  @include flex-around;

  position: relative;

  margin: 0;
  padding: 0 0.5rem;

  padding: 0 10px;

  color: $font-color;

  width: 100%;
  max-width: 2300px;

  height: $toolbar-height;

  @include response-small {
    gap: 0;
  }
}

.section {
  @include flex-start;

  gap: 0.5rem;
  font-weight: bold;

  > a {
    padding: 1px 6px;
    transition: color 0.2s ease;

    &:hover {
      color: $archidekt-orange;
    }
  }

  button {
    background: transparent;
    border: none;
    color: $font-color;
    font-weight: bold;
    transition: color 0.2s ease;

    &:hover:enabled {
      cursor: pointer;
      color: $archidekt-orange;
    }
  }

  @include response-medium {
    gap: 0.5rem;
  }

  @include response-small {
    gap: 15px;

    & > * {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px !important;
      margin: 0;
      padding: 0;

      color: $font-color;
    }
  }
}

.searchTrigger {
  background: $framing-color;
  color: white;

  border: none;

  transition: color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: $link-color;
  }
}

.mobileToolbar {
  display: none;

  @include response-medium-small {
    display: unset;
  }
}

.partialMobileToolbarOptions {
  display: none;

  @include response-small {
    display: unset;
  }
}

.forumsAndCollection {
  @include response-medium-small {
    display: none;
  }
}

.searchDecksNews {
  height: 22px;

  @include response-small {
    display: none;
  }
}

.desktopOptions {
  @extend .searchDecksNews;
  @include flex-start;

  & > a {
    padding: 1px 6px;
    transition: color 0.2s ease;

    &:hover {
      color: $archidekt-orange;
    }
  }
}

.dropdown {
  width: 200px;
  z-index: 2;
  left: 0;
  top: 125%;
}
