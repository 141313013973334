@import 'assets/scss/variables.module';

.menu {
  max-height: 250px;
  overflow-y: auto;

  width: 100%;
}

.input,
.container {
  width: 100%;
}
