@import 'assets/scss/variables.module.scss';

.toast {
  width: 100%;
  margin-left: 0.5rem;

  h4 {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  button {
    cursor: pointer;
    color: $archidekt-orange;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    background-color: transparent;
  }
}

.modal {
  a {
    color: $archidekt-orange;
    font-weight: bold;
  }
}

.accordion {
  margin-top: 1rem;
  margin-left: 1rem;

  pre {
    color: $code-font-color;
    background-color: $light-background2;
    border: 1px solid $light-background;

    padding: 1rem;
    border-radius: 1rem;
  }
}
