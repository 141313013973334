@import 'assets/scss/variables.module';

@mixin scrollbar {
  ::-webkit-scrollbar {
    width: 8px !important;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  ::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-hover;
  }
}
