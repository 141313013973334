@import 'assets/scss/variables.module';

.card {
  @include flex-around;

  position: relative;

  width: 100%;
  height: 30px;
  min-height: 30px;

  margin: 0;

  min-width: 225px;

  border-top: 1px solid $light-background2;

  @include response-small {
    flex-wrap: wrap;
    height: auto;
  }

  transition: background-color 0.2s ease;

  &:hover {
    background-color: $light-background2;
  }
}

.cardName {
  flex: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.button {
  font-weight: bold;

  background: transparent;
  border: none;

  color: $color;

  text-align: left;

  width: 175px;

  display: flex;
  gap: 0.25rem;

  &:hover:enabled {
    cursor: pointer;
    text-decoration: underline;
  }

  i {
    margin: 0 !important;
  }
}

.leftContent {
  @include flex-start;
  flex: 1;
  margin: 0;
  gap: 0.25rem;
}

.rightContent {
  @include flex-end;
  flex: 1;
  margin: 0;
}

.collectionDot {
  display: block;
  height: 10px;
  width: 10px;
  border: 1px solid $color;
  border-radius: 50%;
}

.dragHandle {
  i {
    transition: all 0.2s ease;
    font-weight: bold;
    font-size: 0.9em;
    margin-right: 0 !important;
  }

  &:hover {
    cursor: grab;
    i {
      transform: scale(1.15);
    }
  }
}

.cardError {
  background-color: rgba(red, 0.3);
}

.selected {
  background-color: rgba($archidekt-orange, 0.3) !important;
}

.antiHighlight {
  opacity: 0.15;
}

.hideDropdown {
  opacity: 0;
  pointer-events: none;
}

.shoppingUrl {
  width: 50px;
  white-space: nowrap;
  text-align: right;
}

.setSymbol {
  width: 22px;
  text-align: center;
}

.manaCost {
  width: 100px;
}

.finishes {
  display: block;
  height: 10px;
  width: 10px;
}

.foil {
  border: 1px solid $color;
  background: linear-gradient(-60deg, purple, blue, green, yellow, red);
  opacity: 0.35;
}

.dropdown {
  width: 225px;
  right: 25px;
}

.dimmed {
  opacity: 0.3;
}
