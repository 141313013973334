@import 'assets/scss/variables.module';

.trigger {
  background: transparent;
  color: $color;
  border: none;
  display: flex;

  gap: 0.25rem;
  align-items: center;

  &:hover:enabled {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.splotch {
  display: block;
  height: 10px;
  width: 10px;
  border: 1px solid $color;
  margin: 0;
  padding: 0;
}

.spanWrapper {
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
}

.menu {
  width: 150px;
}
