@mixin mobile-toolbar-button {
  width: 100px;
  height: 100%;

  background-color: transparent;
  border: none;
  color: white;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  span {
    font-size: 0.75em;
  }

  i {
    font-size: 1.3em !important;
  }
}
