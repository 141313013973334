@import 'assets/scss/variables.module.scss';

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: fit-content;
  width: 100%;
  max-width: 35px;

  position: sticky;
  top: 1rem;

  gap: 0.25rem;

  z-index: 2; // Needed to put the controls above the main content of the deck container

  @include response-small {
    display: none; // Can we make it so that this component becomes the mobile toolbar :thinking_face:
  }
}

.pinnedCategoryEnabled {
  top: calc(1rem + 310px);
}

.button {
  // font-size: 1.5em;

  aspect-ratio: 1;

  background-color: transparent;
  border: none;
  color: $color;

  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  border-radius: 5px;

  &:hover:enabled {
    cursor: pointer;

    color: $background-color;
    background-color: $color;
  }

  &:disabled {
    opacity: 0.5;
  }

  i {
    margin-right: 0 !important;
  }
}

.extrasMenu {
  width: 215px;

  left: 0;
}

.save {
  @extend .button;

  &:hover:enabled {
    background-color: transparent;
    cursor: default;
  }

  @include response-small {
    display: none;
  }
}
