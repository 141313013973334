.button {
  display: flex;
  justify-content: center;
  overflow: hidden;
  font-weight: bold;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0;
  }
}

.triggerWrapper {
  width: 100%;
}
