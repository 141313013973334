.container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  pointer-events: none;
  overflow: hidden;
}

.ribbon {
  height: 5px;
  width: 55px;

  position: absolute;

  top: 15px;
  left: -10px;

  transform: rotate(-45deg);
}
