@import 'assets/scss/variables.module';

.container {
  max-width: 1500px;
}

.content {
  padding-top: 1rem;
  width: 100%;
}

.label {
  font-weight: bold;
  font-size: 16px;

  color: $color;
  margin-bottom: 0.5rem;
}

.input {
  flex: 1;

  width: 300px;
  max-width: 100%;

  height: 39px;

  @include response-small {
    width: 100%;
  }
}

.controls {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

body:global(.search-v2-locked) .controls {
  width: 100%;
}

.center {
  @include flex-center;

  margin: 2rem 0;
}

.nextPrevContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  @include response-small {
    display: none;
  }
}

.mobileNexPrevContainer {
  justify-content: flex-end;
}

.nextPrevButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  background-color: $background-color;
  border: 1px solid $light-color2;
  color: $color;

  padding: 0.75rem 1rem;
  border-radius: 5px;

  transition: filter 0.2s ease-in-out;

  width: 200px;
  overflow: hidden;

  font-weight: bold;

  i {
    margin-right: 0 !important; // fuck semantic
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    max-width: 150px;
  }

  &:hover:enabled {
    cursor: pointer;
    filter: brightness(1.2);
  }

  &:disabled {
    opacity: 0.6;
  }

  @include response-small {
    background-color: transparent;
    border: none;
    color: white;
    width: 120px;
  }
}

.checkboxContainer {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;

  padding: 0.5rem 0;
}

.checkbox {
  padding: 0 !important; // overwriting semantic
}

.formElements {
  @extend .controls;

  justify-content: flex-start;
  width: unset;

  @include response-small {
    gap: 1.5rem;
    width: 100%;
  }
}
