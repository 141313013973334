@import 'assets/scss/variables.module.scss';

.button {
  border-radius: 5px;

  border: 1px solid $light-color2;
  background-color: $light-background;
  color: $color;

  padding: 0.75rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-weight: bold;
  white-space: nowrap;
  height: 39px;

  transition: background-color 0.2s ease-in-out;

  i {
    margin: 0 !important;
  }

  &:hover:enabled {
    cursor: pointer;

    background-color: $light-background2;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.colored {
  transition: filter 0.2s ease-in-out;

  &:hover:enabled {
    background-color: inherit; // doesn't work, sad
    filter: brightness(1.1);
  }
}

.green {
  @extend .colored;

  background-color: $button-green;
  color: white;

  &:hover:enabled {
    background-color: $button-green;
  }
}

.orange {
  @extend .colored;

  background-color: $archidekt-orange;
  color: white;

  &:hover:enabled {
    background-color: $archidekt-orange;
  }
}

.red {
  @extend .colored;

  background-color: $button-red;
  color: white;

  &:hover:enabled {
    background-color: $button-red;
  }
}

.blue {
  @extend .colored;

  background-color: $button-blue;
  color: white;

  &:hover:enabled {
    background-color: $button-blue;
  }
}
