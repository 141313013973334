@import 'assets/scss/variables.module.scss';

.container {
  max-width: 1000px;
}

.content {
  width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 1.15rem;
  font-weight: bold;
}

.table {
  display: flex;
  flex-direction: column;

  border: 1px solid $light-color2;
  border-radius: 5px;

  & > *:nth-child(odd) {
    background-color: $light-background;
  }

  & > *:nth-child(even) {
    background-color: $light-background2;
  }
}

.row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 0.5rem 1rem;
}

.primaryForm {
  display: flex;
  justify-content: space-between;

  @include response-medium {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.tagControls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.35rem 1.5rem;

  color: white;
  border: none;
  border-radius: 0.25rem;

  transition: filter 0.2s ease-in-out;

  i {
    margin: 0 !important;
  }

  &:hover:enabled {
    cursor: pointer;
    filter: brightness(1.3);
  }

  &:disabled {
    opacity: 0.5;
  }

  @include response-medium {
    width: 100%;
  }
}

.editButton {
  @extend .button;

  background-color: $button-blue;
}

.deleteButton {
  @extend .button;

  background-color: $button-red;
}

.controls {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  margin-top: 1rem;
}

.addTag {
  @extend .button;

  background-color: $button-blue;
  padding: 0.75rem 2.5rem;

  i {
    margin-right: 0 !important;
  }
}

.colorPicker {
  margin-left: calc(15px + 0.5rem);
}

.tagInput {
  border-radius: 3px;

  border: solid 1px $light-color2;
  background-color: $light-background;
  color: $color;

  &:focus {
    outline-color: $archidekt-orange;
  }
}

.noTagsMessage {
  padding: 1rem;

  color: $light-color2;
  text-align: center;
  font-weight: bold;
}
