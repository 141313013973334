@import 'assets/scss/variables.module';

.form {
  width: 100%;
  margin-bottom: 1rem;

  button {
    white-space: nowrap;
  }
}

.stickyForm {
  position: sticky;
  top: -65px;

  z-index: 2;

  width: 100%;

  display: flex;
  justify-content: center;

  background-color: $overlay-background-color;

  @include response-small {
    top: -1rem;
  }
}

body:global(.search-v2-locked) .form {
  width: 100%;
}

.row {
  @include flex-around;

  color: $color;

  width: 100%;

  margin-top: 0;

  input[type='text'] {
    flex: 1;
  }

  // Semantic 😔
  :global(.ui.input),
  :global(.ui.multiple.selection.dropdown) {
    flex: 1;
  }

  @include response-small {
    flex-wrap: wrap;
  }

  @include response-x-small {
    & > * {
      width: 100%;
    }
  }
}

body:global(.search-v2-locked) .row {
  flex-wrap: wrap;
}

.info {
  @include flex-around;
  font-style: italic;
  color: $light-color;
  margin-top: 5px;
}

.modifiedFields {
  color: darkorange;
  font-weight: bold;
}

.autocomplete {
  width: 100%;

  input {
    width: 100%;
    border-radius: 4px !important;
  }

  :global(.ui.icon.input) {
    width: 100%;
  }
}

.searchInputRow {
  width: 100%;

  display: flex;
  gap: 0.5rem;

  margin-bottom: 1.5rem;

  & > div {
    width: 100%;
  }
}

.accordionContainer {
  margin: 5px 0;
  position: relative;

  :global .clear {
    padding: 5px 10px;
    position: absolute;
    top: 2px;
    right: 0;
    font-weight: bold !important;
  }

  & > button {
    margin: 5px 0;
  }
}
