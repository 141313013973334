@import 'assets/scss/variables.module.scss';

.container {
  max-width: 1050px;
}

.content {
  height: 100%;
  overflow: visible;

  width: 100%;

  @include response-small {
    margin: unset;
  }
}

.title {
  color: $color;
  font-size: 1.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
}

.defaultCard {
  color: $light-color;
}

.basicCard {
  min-height: 400px;

  @include response-small {
    min-height: auto;
  }
}

.rotationContainer {
  transition: transform 0.2s ease-in-out;

  position: relative; // used for spinner centering
}

.rotated {
  transform: rotate(90deg) scale(0.9);
}

.oneEightyRotate {
  transform: rotate(180deg);
}

.controls {
  position: relative;
  margin-bottom: 3rem;
}

.tabContainer {
  justify-content: flex-start;
  width: 100%;

  border-bottom: 3px solid $light-background;

  & > * {
    flex: unset;
  }

  @include response-small {
    flex-wrap: unset;
  }
}

.tabButton {
  font-weight: bold;

  margin: -3px 0;
  padding: 0.5rem 1rem;
}

.activeTab {
  border-bottom: 3px solid $archidekt-orange;
}

.rightControls {
  position: absolute;

  top: 0;
  right: 0;

  margin: -1rem 0;

  @include response-medium-small {
    position: unset;
    margin: unset;
  }
}

.left {
  width: 33%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.right {
  width: 66%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.left,
.right {
  @include response-medium {
    width: 100%;

    justify-content: center;
  }
}

.splitLayout {
  display: flex;
  gap: 1rem;

  @include response-medium {
    flex-wrap: wrap;
  }
}

.nextPrevContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  padding-top: 1rem;

  @include response-small {
    display: none;
  }
}

.mobileNexPrevContainer {
  justify-content: flex-end;
}

.nextPrevButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  background-color: $background-color;
  border: 1px solid $light-color2;
  color: $color;

  padding: 0.75rem 1rem;
  border-radius: 5px;

  transition: filter 0.2s ease-in-out;

  width: 200px;
  overflow: hidden;

  font-weight: bold;

  i {
    margin-right: 0 !important; // fuck semantic
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    max-width: 150px;
  }

  &:hover:enabled {
    cursor: pointer;
    filter: brightness(1.2);
  }

  &:disabled {
    opacity: 0.6;
  }

  @include response-small {
    background-color: transparent;
    border: none;
    color: white;
    width: 120px;
  }
}

.extraArea {
  border-top: 3px solid $light-background;
  margin-top: 2rem;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.flipActionGroup {
  @extend .row;

  gap: 0;
}

.flipButton {
  padding: 0.75rem 1rem;

  background-color: $button-purple;
  color: white;

  border-radius: 5px;
  border: none;

  font-weight: bold;

  transition: opacity 0.2s ease-in-out;

  &:hover:enabled {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.hideFlipCardActions {
  opacity: 0;
  pointer-events: none;
}

.flipLockEnabled {
  border-radius: 5px 0 0 5px;
}

.flipLockButton {
  @extend .flipButton;

  border-radius: 0 5px 5px 0;
  border-left: 1px solid rgb(213, 213, 213);
}

.defaultRightLinks {
  display: flex;
  gap: 0.5rem;

  @include response-small {
    display: none;
  }
}

.cardTimestampsDropdown {
  background-color: transparent;
  color: $color;
  border: none;

  &:hover:enabled {
    cursor: pointer;
  }

  i {
    margin: 0 !important;
  }
}

.cardTimestampsMenu {
  width: 225px;
  top: 2.5rem;
}

.titleRow {
  @extend .row;

  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  height: 32px;
}

.savingOverlay {
  position: absolute;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  background-color: white;
  border-radius: 15px;
  opacity: 0.25;
}

.loader {
  margin-bottom: 100px;
}
