@import 'assets/scss/variables.module.scss';

.triggerWrapper {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}

.trigger {
  color: white;
  background-color: $archidekt-orange;
  font-weight: bold;

  border: 1px solid $light-background2;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  transition: filter 0.2s ease;

  &:hover:enabled {
    filter: brightness(1.2);
    cursor: pointer;
  }
}

.menu {
  top: 105%;
  width: 320px;
}
