@import 'assets/scss/variables.module';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  color: $color;
}

.icon {
  margin: 0 !important;
  display: unset !important;

  cursor: pointer;
}
