@import 'assets/scss/variables.module';

.container {
  height: 250px;
  width: 250px;

  background: $light-color2;
  color: $color;

  border: none;
  border-radius: 5px;

  transition: box-shadow 0.05s linear, transform 0.2s ease-in-out;

  &:hover:enabled {
    transition-delay: 0.1s;

    cursor: pointer;

    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    transform: translateY(-5px);
  }
}

.tabTitle {
  font-size: 20px;
  font-weight: bold;
}
