@import 'assets/scss/variables.module';

.form {
  display: flex;
  gap: 0.5rem;

  width: 100%;

  margin-bottom: 1rem;
}
