@import 'assets/scss/variables.module';

.dropdown {
  width: 100%;
  border: 1px solid $light-color2 !important;

  input {
    color: $color;
  }

  :global(.message) {
    color: $color !important;
  }

  :global(.divider.text) {
    color: $color !important;
  }

  :global(.default.text) {
    color: $light-color2 !important;
  }
}

.container {
  @include flex-start;

  width: 100%;

  flex-direction: column;
  align-items: flex-start;
  gap: 0;

  label {
    font-weight: bold;
  }
}

.disabled {
  opacity: 0.65;
}
