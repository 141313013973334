@import 'assets/scss/variables.module';

.container {
  @include flex-even;
  flex-wrap: wrap;

  width: 600px;
  max-width: 100%;

  gap: 0;

  & > * {
    flex: 1;
    white-space: nowrap;

    @include response-small {
      width: 100%;
    }
  }
}

.button {
  color: $color;

  flex: 1;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;

  background: transparent;

  // border-bottom: 3px solid $light-color2;
  margin: 0;
  font-weight: 0;

  @media (max-width: 400px) {
    font-size: 75%;

    img {
      height: 10px;
    }
  }

  &:hover:enabled {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.selected {
  font-weight: 600;
  border-bottom: 3px solid $archidekt-orange;
}

.overflowOptionsTrigger {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.overflowOptionsMenu {
  width: 220px;
}
