@import 'assets/scss/variables.module';

.lockables {
  display: flex;
}

.content {
  flex: 1;
  min-width: 0;
}

.main {
  background-color: $background-color;
  color: $color;

  transition: background-color 0.15s linear;

  flex: 1;

  position: relative;

  // 410 is 50 + 360 : 50px for the global toolbar, 360 for the footer
  // I don't love this
  min-height: calc(100vh - 410px);
}
