@import 'assets/scss/variables.module.scss';

.container {
  position: relative;

  @include response-small {
    width: 100%;
  }
}

.header {
  position: absolute;

  top: -1.4rem;
  left: 0;

  font-weight: bold;
  white-space: nowrap;
}

.inlineHeader {
  position: relative;
  top: unset;
}

.input {
  border-radius: 5px;

  border: 1px solid $light-color2;
  background-color: $light-background;
  color: $color;

  padding: 0.75rem 1rem;

  transition: background-color 0.2s ease-in-out;

  -webkit-outer-spin-button,
  -webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  &:hover:enabled,
  &:focus:enabled {
    cursor: text;
    background-color: $light-background2;
  }

  &:disabled {
    opacity: 0.5;
  }
}
