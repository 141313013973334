@import 'assets/scss/variables.module';

.container {
  position: relative;

  :global {
    .ui.search.selection.dropdown {
      width: 100%;
    }

    .search {
      color: $color;
    }

    .message {
      color: $secondary-text-color !important;
    }

    .divider.default.text {
      color: $color !important;
      z-index: unset !important;
    }
  }
}

.insertedOption {
  width: 100%;
  display: flex;
  justify-content: center;
}

.owned {
  width: 10px;
  height: 10px;

  border-radius: 50%;
  padding-right: 5px;

  display: inline-block;
}

.option {
  display: flex;
  gap: 0.5rem;
}

.words {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.setName {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;

  display: flex;
  gap: 0.25rem;
  align-items: center;

  span {
    font-size: 10px;
    color: $light-color;
  }
}

.setInfo {
  font-size: 12px;
  color: $light-color;
}

.symbols {
  width: 25px;

  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 0 !important;
  }
}
