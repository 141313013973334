@import 'assets/scss/variables.module.scss';

.container {
  max-height: 330px;
  overflow: auto;

  border-radius: 5px;
  border: 2px solid $light-color2;

  background-color: $background-color;

  padding: 0.5rem 0;

  @include response-small {
    max-height: unset;
  }
}

.sumOfCards {
  @extend .container;

  padding: 1rem;
  font-weight: bold;
}

.group {
  margin-bottom: 1.5rem;
}

.header {
  font-size: 1.125rem;
  font-weight: bold;

  margin-bottom: 1rem;

  padding: 0 1rem;

  color: $color;
}

.loader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  padding: 0.25rem 1.5rem;

  pointer-events: none;

  color: $color;
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  padding: 0.25rem 1.5rem;
}

.stripes {
  @include stripes;
}

.addNewCard {
  display: flex;
  justify-content: flex-end;

  margin: 1rem;
}
