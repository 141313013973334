@import 'assets/scss/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  label {
    font-weight: bold;
  }

  button {
    font-weight: bold;
    color: white;
    background-color: $button-blue;

    border: none;
    border-radius: 5px;

    padding: 0.25rem 0.75rem;

    &:hover:enabled {
      cursor: pointer;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.labelOption {
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding-right: 1rem;
}

.dropdownTrigger {
  width: 100%;

  color: $color;
  background: $light-background;

  border: 1px solid $light-color2;
  border-radius: 5px;

  padding: 0.75rem 1rem;

  &:hover:enabled {
    cursor: pointer;
  }
}

.menu {
  width: 100%;

  max-height: 250px;
  overflow: auto;

  z-index: 2; // to account for cursor card on table view

  & > * {
    padding: 0;
  }
}

.colorLabel {
  flex: 1;

  padding: 0.5rem 1rem;
}

.appliedTags {
  display: flex;
  flex-direction: column;

  padding: 0.5rem 1rem;

  font-weight: bold;
}

.noLabels {
  color: $light-color2;
  font-weight: bold;

  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.labelsDropdown {
  width: 100%;
}

.defaultTriggerStyles {
  &:disabled {
    cursor: default;

    opacity: 0.5;
  }
}

.noLabelsMessage {
  text-align: center;
  width: 100%;
  font-weight: bold;
}
