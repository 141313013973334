@import './mediaQueries.module.scss';

// The mixins in this class are used to contain the madness that is getting the page wide scrolling to work for spreadsheet view
// In an attempt to contain the madness, ALL (most) specific css related to this view is contained here
// Anywhere these mixens are being used is to facilitate the correct viewing of the deck page's table view

@mixin sticky-wide-table-page {
  body:global(.deck-overscroll-view) & {
    width: 100vw;

    position: sticky;
    left: 0;
  }
}

@mixin sticky-wide-table-deck-page {
  @include sticky-wide-table-page;
}

@mixin deck-content-padding {
  body:global(.deck-overscroll-view) & {
    padding: 0 1rem;

    @include response-small {
      padding: 0 0.25rem;
    }
  }
}

@mixin not-deck-content-padding {
  body:global(:not(.deck-overscroll-view)) & {
    padding: 0 1rem;

    @include response-small {
      padding: 0 0.25rem;
    }
  }
}

@mixin sticky-wide-table-deck-content {
  @include sticky-wide-table-page;
  @include deck-content-padding;

  body:global(.deck-overscroll-view) & {
    width: 100vw;
  }
}
