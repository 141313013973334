@import 'assets/scss/variables.module';

.toggleBox {
  display: flex;
  align-items: center;
  gap: 1rem;

  width: 350px;
  max-width: 100%;
  height: 100px;

  background-color: $background-color;
  border: 1px solid $light-color2;
  border-radius: 5px;

  padding: 1rem;

  &:hover:enabled,
  &:focus:enabled {
    cursor: pointer;
    background-color: $light-background;
  }

  div {
    color: $color;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    text-align: left;

    pointer-events: none;
  }

  h4 {
    margin: 0;
  }

  i {
    margin: 0 !important;
    font-size: 1.5em;

    color: $color;
  }

  .checked {
    color: $button-green;
  }
}
