.wrapper {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.trigger {
  position: fixed;
  opacity: 0;
  background: transparent;

  pointer-events: none;
  user-select: none;

  transform: scale(0);

  height: 0;
  width: 0;
}

.menu {
  position: absolute;
  display: flex;
}

.overlay {
  position: fixed;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  overflow: hidden;

  z-index: 10;
}
