@import 'assets/scss/variables.module';

.dropdownTrigger {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  @include response-small {
    padding: 0;

    height: 36px;
    width: 38px;

    i {
      margin-right: 0 !important;
    }
  }
}

.username {
  @include response-small {
    display: none;
  }
}

.dropdownMenu {
  width: 250px;
  top: 125%;

  left: -120px;

  @include response-small {
    left: -150px;
  }
}

.deckList {
  display: flex;
  flex-direction: column;
  gap: 2px;

  width: 100%;
  max-height: 125px;
  overflow-y: auto;

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  text-decoration: none;
  text-align: left;

  padding: 0;

  &:hover {
    background-color: unset !important;
  }

  & > a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;

    padding-left: 15px;

    transition: background-color 0.1s ease;

    &:hover {
      background-color: $light-color2;
      color: $color !important;
    }

    & > img {
      height: 35px;
      width: 35px;

      margin-right: 8px;

      object-fit: cover;
      border: 1px solid $light-color2;
    }

    & > span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mod {
  i {
    background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 2s ease-in-out infinite;
    background-size: 400% 100%;
  }
}

@keyframes rainbow_animation {
  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

.decksLoading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  pointer-events: none;
}

@media (min-height: 1000px) {
  .deckList {
    max-height: 300px;
  }
}
