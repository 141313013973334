@import 'assets/scss/variables.module.scss';

.container {
  position: relative;
  display: flex;

  @include response-small {
    width: 100%;
  }
}

.inlineContainer {
  flex-direction: column;
}

.header {
  position: absolute;

  top: -1.4rem;
  left: 0;

  font-weight: bold;
  white-space: nowrap;
}

.inlineHeader {
  position: unset;
}

.triggerWrapper {
  @include response-small {
    flex: 1;
  }
}

.trigger {
  padding: 0.75rem 1rem;
  white-space: nowrap;

  border-radius: 5px;

  background-color: $light-background;
  border: solid 1px $light-color2;
  color: $color;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;

  min-width: 140px;
  width: 100%;

  @include response-small {
    min-width: unset;
  }

  height: 39px;

  i {
    pointer-events: none;
  }

  .leftContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.25rem;

    flex: 1;
    text-align: left;
    pointer-events: none;
  }

  .icon {
    color: $archidekt-orange;
    user-select: none;
  }

  transition: background-color 0.2s ease-in-out;

  &:hover:enabled,
  &:focus:enabled {
    cursor: pointer;
    background-color: $light-background2;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.menu {
  max-height: 250px;
  width: 100%;
  overflow: auto;
}

.hasExtraOptions {
  border-radius: 5px 0 0 5px;
}

.placeholder {
  opacity: 0.5;
}
