@import 'assets/scss/variables.module.scss';

$size: 38px;

.container {
  position: absolute;
  top: 0;
  right: 0;

  width: $size;
  height: $size;

  & > div:first-child {
    width: $size;
    height: $size;
  }
}

.cornerQuantity {
  border-radius: 0px 11px 0px 0px !important;
  border: none;
  overflow: hidden;

  font-weight: bold !important;
  user-select: none;

  clip-path: polygon(100% 0, 0 0, 100% 100%);

  display: flex;
  justify-content: flex-end;
  padding: 3px;

  width: 100%;
  height: 100%;

  font-size: 12px;

  background-color: $archidekt-orange;
  color: white;
}
