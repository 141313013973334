@import 'assets/scss/variables.module';

.container {
  max-width: 1350px;
}

.tabLine {
  position: relative;
  margin-bottom: 1rem;

  width: 100%;

  @include response-small {
    margin-bottom: 1rem;
  }
}

.rightControls {
  position: absolute;

  display: flex;
  gap: 0.5rem;

  top: 0;
  right: 0;

  margin: -0.25rem 0;

  @include response-medium-small {
    position: unset;
    margin: unset;
  }

  .extrasSimpleButton {
    background-color: transparent;
    color: $color;
    border: none;

    font-weight: bold;
    padding: 0.75rem 1rem;

    @include response-small {
      display: none;
    }
  }
}

body:global(.search-v2-locked) .rightControls {
  position: unset;
}

.toTop {
  position: fixed;

  bottom: 1rem;
  left: 1rem;

  border: 3px solid $archidekt-orange;

  @include response-small {
    display: none;
  }
}

body:global(.mediavine-ads-enabled) .toTop {
  bottom: calc(1.5rem + 90px);
}

.mobileControls {
  @include response-small {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 0.5rem;
    justify-items: center;

    button {
      @include mobile-toolbar-button;
    }
  }
}

.viewAllTabsMobile {
  width: 1.3rem;
  height: 1.3rem;

  border-radius: 3px;

  background-color: #fa890d;
  color: white;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
}

.lockButton {
  background-color: transparent;
  border: none;

  color: $color;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  position: fixed;

  top: calc(50px + 1.25rem);
  left: 1rem;

  span {
    font-size: 0.75em;
  }

  i {
    font-size: 1.5em;
    margin: 0 !important;
  }

  &:hover:enabled {
    cursor: pointer;
  }

  @include response-medium {
    display: none;
  }
}

.lockWrapper {
  display: flex;
}

.lockedContainer {
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  height: 100vh;
  position: sticky;
  top: 0;

  background-color: $overlay-background-color;

  padding-bottom: 200px;
}

.lockedContent {
  height: fit-content;
  min-height: 100%;
  padding: 1rem;

  padding-right: 25px; // width of the resize handle

  position: relative;
}

.draggable {
  height: 100%;
  width: 20px;

  background-color: transparent;
  border: none;

  cursor: e-resize;

  &:focus {
    outline: none;
  }

  background-color: $framing-color;
  color: white;

  display: flex;
  justify-content: center;

  i {
    margin-right: 0 !important;
  }
}

.overflowControls {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include response-small {
    display: none;
  }
}

.overflowButtons {
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  width: 25px;
  height: 25px;

  &:hover:enabled {
    cursor: pointer;
  }
}

.newTabButton {
  @extend .overflowButtons;

  background-color: transparent;
  color: $button-blue;

  transition: filter 0.2s ease-in-out;

  i {
    margin: 0 !important;
  }

  &:hover:enabled {
    background-color: transparent;
    filter: brightness(1.1);
  }
}

.allTabsButton {
  @extend .overflowButtons;

  margin: -7px 0 !important; // overwrite parent padding (this button is taller)

  border-radius: 3px;

  background-color: #fa890d;
  color: white;
  font-weight: bold;
}
