@import 'assets/scss/variables.module';

.container {
  display: flex;
  gap: 2.5rem;

  @include response-medium {
    gap: 1rem;
  }

  @include response-small {
    gap: 0;
  }

  button {
    background-color: transparent;
    color: white;
    border: none;

    font-weight: bold;
    font-size: 1.25rem;

    cursor: pointer;
    height: 50px;
  }
}
