@import 'assets/scss/variables.module';

.logo {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.archidektWord {
  margin-left: -5px;
}

.hideWordOnMobile {
  @include response-small {
    display: none;
  }
}

.hide {
  display: none;
}
