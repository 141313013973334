@import 'assets/scss/variables.module.scss';

.trigger {
  padding: 0.75rem 1rem;

  background: $light-background;
  color: $color;

  border: 1px solid $light-color2;
  border-radius: 5px;

  white-space: nowrap;

  min-width: 110px;

  &:hover:enabled {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.optionsMenu {
  width: 100%;
}
