@import 'assets/scss/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding-bottom: 2rem;
}

.emptyStack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: bold;

  width: 100%;

  border-radius: 3px;
  background-color: $light-background3;
  border: 1px solid $light-color2;
}
