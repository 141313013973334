@import 'assets/scss/variables.module';

.trigger {
  height: 39px;
  border-color: $light-color2;
}

.option {
  display: flex;
  gap: 0.5rem;
}

.words {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.setName {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;

  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.metaInfo {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  color: $light-color;

  width: 100%;
  gap: 0.25rem;
}

.header {
  font-weight: bold;
  white-space: nowrap;
}

.absoluteHeaderContainer {
  position: relative;
}

.absoluteHeader {
  position: absolute;
  top: -22px;
}

.disabled {
  opacity: 0.6;
}
