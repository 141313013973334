@import 'assets/scss/variables.module';

.recentlyAdded {
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
}

.recentlyAddedGrid {
  display: grid;
  gap: 0.5rem;

  grid-template-columns: repeat(5, 1fr);

  @include response-large {
    grid-template-columns: repeat(4, 1fr);
  }

  @include response-medium {
    grid-template-columns: repeat(3, 1fr);
  }

  @include response-small {
    grid-template-columns: repeat(2, 1fr);
  }
}

.basicCard {
  width: 100%;
  min-width: unset;

  height: auto;
  min-height: unset;
}

.cardImage {
  overflow: hidden;
  position: unset;
}

.noRecentCards {
  width: 100%;
  text-align: center;
  color: $light-color;
  font-weight: bold;
}
