@import 'assets/scss/variables.module';

.button {
  color: $color;

  background: transparent;
  border: none;

  &:hover:enabled {
    cursor: pointer;
  }

  i,
  img {
    margin-right: 0.55rem;
  }

  & > span {
    display: block;
  }

  &:disabled {
    opacity: 0.6;
  }
}

.transition {
  transition: all 0.2s ease;
}

.buttonContent {
  @include response-medium-small {
    display: none;
  }
}

.primaryContent {
  flex: 1;
}

.keybindButton {
  display: flex !important;
  justify-content: space-between;
}

.keybind {
  opacity: 0.4;
  padding-right: 0.6rem;
}
