@import 'assets/scss/variables.module.scss';

.modal {
  max-width: 500px;
  padding: 1rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 2rem;

  h2 {
    margin: 0;
    text-align: center;
  }
}

.info {
  text-align: center;
  font-size: 16px;
}

.deleteButton {
  background-color: $button-red;
  color: white;
  font-weight: bold;

  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;

  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover:enabled {
    cursor: pointer;
    filter: brightness(1.2);
  }

  &:disabled {
    opacity: 0.6;
  }
}
