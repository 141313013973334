@import 'assets/scss/variables.module.scss';

.horizontalRule {
  border-bottom: 2px solid $light-color2;
  margin: 1rem 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cardDetailControls {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  button {
    color: white;

    font-weight: bold;
    border-radius: 5px;
    border: 1px solid $light-background2;

    padding: 0.5rem 1rem;

    transition: filter 0.2s ease-in-out;

    &:hover:enabled {
      filter: brightness(1.2);
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.backButton {
  background-color: $button-red;
}

.saveButton {
  background-color: $button-green;
}

.hideOnMobile {
  @include response-small {
    display: none;
  }
}

.gameTypeSelectorContainer {
  padding: 1rem;
  padding-top: 2rem;

  border: 1px solid $light-color2;
  border-radius: 5px;
  background-color: $light-background;
}
