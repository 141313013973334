@import 'assets/scss/variables.module';

@font-face {
  font-family: 'Archidekt Icons';
  src: url('../fonts/archidektIcons.ttf');
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

a {
  color: inherit;
}
.ui.page.modals.dimmer.transition.visible.active {
  overflow: auto;
}
.ui.selection.active.dropdown {
  border: 1px solid #ff992d;
  box-shadow: 0 0 10px #ff992d;
}
.ui.selection.active.dropdown .menu {
  border: 1px solid #ff992d;
}
.ui.selection.active.dropdown:hover {
  border: 1px solid #ff992d;
  box-shadow: 0 0 10px #ff992d;
}
.ui.selection.active.dropdown:hover .menu {
  border: 1px solid #ff992d;
}
.ui.form input[type='text'] {
  border: 1px solid $input-border-color;
}
.ui.form input[type='text']:focus {
  border: 1px solid #ff992d;
  box-shadow: 0 0 10px #ff992d;
}
.ui.form input[type='password'] {
  border: 1px solid $input-border-color;
}
.ui.form input[type='password']:focus {
  border: 1px solid #ff992d;
  box-shadow: 0 0 10px #ff992d;
}
.ui.page.modals.dimmer.transition.visible.active {
  z-index: 1006;
}
.ui.modal.transition.visible.active {
  display: flex !important;
  flex-direction: column;
}

.fixedDimmer {
  position: fixed !important; //This is needed to make dimmers work with full page infinite scrollers
}
i.icon.scg {
  font-family: 'Archidekt Icons' !important;
  &:before {
    content: '\e900';
  }
}
i.icon.scg-simple {
  font-family: 'Archidekt Icons' !important;
  &:before {
    content: '\e905';
  }
}
i.icon.cardKingdom {
  font-family: 'Archidekt Icons' !important;
  &:before {
    content: '\e901';
  }
}
i.icon.tcg {
  font-family: 'Archidekt Icons' !important;
  &:before {
    content: '\e902';
  }
}
i.icon.cardhoarder {
  font-family: 'Archidekt Icons' !important;
  &:before {
    content: '\e903';
  }
}
i.icon.cardmarket {
  font-family: 'Archidekt Icons' !important;
  &:before {
    content: '\e904';
  }
}
i.icon.manapool {
  font-family: 'Archidekt Icons' !important;
  &:before {
    content: '\e906';
  }
}
i.icon.tcgland {
  font-family: 'Archidekt Icons' !important;
  &:before {
    content: '\e907';
  }
}

i.icon.mtgo {
  font-family: 'Keyrune' !important;
  &:before {
    content: '\e91b';
  }
}
i.icon.arena {
  font-family: 'Keyrune' !important;
  &:before {
    content: '\e943';
  }
}

// Theme based
.ui.modal,
.ui.modal > .header,
.ui.modal > .actions,
.ui.segment,
.ui.vertical.tabular.menu .active.item,
.ui.table,
.ui.modal > .content,
.ui.tabular.menu .active.item,
.ui.popup {
  background-color: $background-color;
  color: $color;
}

.ui.form .field > label,
.ui.checkbox label,
.ui.form .inline.fields > label,
label,
.ui.inverted.dimmer .ui.loader,
.ui.loader,
.ui.search > .results .result .title,
.ui.statistic > .label,
.ui.statistic > .value {
  color: $color;
}

.ui.loading.dropdown > i.icon:after {
  border-color: $light-color transparent transparent;
}

.ui.secondary.pointing.menu .active.item {
  color: $color;
  border-color: $color;
}

.ui.menu .item {
  color: $color;
  opacity: 0.95;
}

.ui.secondary.pointing.menu .active.item:hover {
  color: $color;
  border-color: $color;
  opacity: 0.95;
}

.ui.secondary.pointing.menu a.item:hover {
  color: $color;
  opacity: 0.87;
}

.ui.pagination.menu .item {
  color: #000;
}

.ui.form input[type='text'],
.ui.form input[type='password'],
.ui.form input[type='text']:focus,
.ui.form input[type='password']:focus,
.ui.dropdown .menu > .item,
.ui.search > .results,
.ui.dropdown .menu,
.ui.dropdown .menu > .header,
.ui.input > input,
.ui.input.focus > input,
.ui.input > input:focus {
  background-color: $light-background;
  color: $color;
}

.ui.form .field.field input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill:focus {
  background: $light-background;
  color: $color !important;
  -webkit-text-fill-color: $color;
  -webkit-box-shadow: 0 0 0px 1000px $light-background inset !important;
}

.ui.fluid.dropdown,
//.ui.search.selection.dropdown>input.search,
.ui.dropdown .menu>.item ,
.ui.selection.dropdown,
.ui.form textarea,
.ui.form textarea:focus {
  background: $light-background !important;
  border: 1px solid $input-border-color;
  color: $color;
}

.ui.selection.dropdown .menu > .item {
  border-top: 1px solid $light-color3;
}

.ui.inverted.dimmer {
  background-color: rgba($rgb-background, 0.85);
  color: $color;
}

.solidDimmer {
  background-color: $background-color !important;
}

.ui.dropdown .menu .selected.item {
  color: $color;
  font-weight: bolder;
}

.ui.search > .results .result:hover {
  background-color: $background-color;
}

.ui.dropdown .menu > .item:hover {
  color: $color;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.ui.styled.accordion,
.ui.accordion .title:not(.ui),
.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
  background-color: $background-color !important;
  border-color: $table-border-color;
}

.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
  color: rgba($rgb-color, 0.4);
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
  box-shadow: 0 1px 2px 0 $table-border-color, 0 0 0 1px $table-border-color;
}

.ui.styled.accordion .title:hover,
.ui.styled.accordion .accordion .title:hover,
.ui.styled.accordion .title:hover {
  color: rgba($rgb-color, 0.87);
}

.ui.styled.accordion .active.title,
.ui.styled.accordion .accordion .active.title {
  color: rgba($rgb-color, 0.95);
}

.ui.toggle.checkbox input:checked ~ label {
  color: $color !important;
}

.ui.table {
  border-color: $table-border-color !important;
}

.ui.table thead th {
  background-color: $light-background2;
  color: $color;
}

.ui.buttons .or:before {
  background-color: $background-color;
  color: $light-background2;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background-color: $light-background2;
}

.ui.toggle.checkbox label {
  color: $color;
}
.ui.toggle.checkbox input:focus:checked ~ label {
  color: $light-color !important;
}
.ui.toggle.checkbox input:focus ~ label {
  color: $light-color !important;
}

.ui.tabular.menu .item {
  color: $color;
  &:hover {
    color: $light-color;
  }
}

.ui.attached.segment {
  border: 1px solid $light-color2;
}
.ui.vertical.tabular.menu .active.item {
  border: 1px solid $light-color2;
  border-right: none;
}
.ui.vertical.tabular.menu {
  border-right: 1px solid $light-color2;
}

.ui.accordion .title:not(.ui) {
  color: $color;
}
.ui.search > .results {
  border: 1px solid $light-color2;
}
.ui.search > .results .result {
  border-bottom: 1px solid $light-color3;
}
.ui.input > input {
  border: 1px solid $light-color2;
}

.ui.toggle.checkbox input:focus ~ label:before {
  background-color: $light-color2;
}
.ui.toggle.checkbox label:before {
  background-color: $light-color2 !important;
}

.ui.breadcrumb .divider {
  color: $light-color;
}
.ui.message {
  background-color: $light-background3;
  color: $color;
}
.ui.search.dropdown .menu {
  max-height: 17rem !important;
}

.priceContainer {
  color: $color;
}
