@import 'assets/scss/variables.module';

.hamburger {
  height: 25px;
}

.hamburgerColor {
  & > * {
    background-color: #e3e3e3;
  }
}

.menu {
  width: 225px;
  z-index: 2;

  left: -30px;

  i {
    margin-right: 10px;
  }

  @include response-small {
    width: 80vw;
    max-width: 300px;
  }
}

.spacer {
  pointer-events: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 0.1em solid $light-color2;
    width: 100%;
    transform: translateY(-50%);
  }
}
