@import 'assets/scss/variables.module';

.container {
  color: $color;

  padding: 1rem;

  background-color: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;
}

.row {
  @include flex-around;
}

.info {
  padding: 1rem 0;

  span {
    font-weight: bold;
  }
}
