@import 'assets/scss/variables.module';

.container {
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 100%;
}

.row {
  width: 100%;

  display: flex;
  gap: 1rem;

  @include response-small {
    flex-direction: column;
  }
}

.quantityCommanderRow {
  @extend .row;

  justify-content: space-between;
}

.commanderCompanion {
  @extend .row;

  justify-content: flex-end;

  @include response-small {
    margin-bottom: 1rem;
  }
}

.quantityRow {
  margin-bottom: 1rem; // Adjusting for abosolute label, noramlly gaps would take care of this, but in this case, there is not label on the (ironically) "labels" section

  @include response-small {
    margin: 0;
  }
}

.removeExtraMargins {
  margin: 0;
}

.fullWidth {
  width: 100%;

  & > div {
    width: 100%;
  }
}

.editionSelection {
  width: 100%;
}

.prettyBox {
  width: 100%;

  padding: 1rem;

  background-color: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1;

  label {
    font-weight: bold;
  }

  @include response-small {
    width: 100% !important;
  }
}

.left {
  width: 65%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include response-small {
    width: 100% !important;
  }
}

.lockPersonalCategories {
  width: 100%;
}

.right {
  @extend .prettyBox;

  width: 35%;

  gap: 0.5rem;

  & > * {
    width: 100%;
  }
}

.sideMaybeRow {
  @extend .row;

  gap: 0.5rem;

  @include response-large {
    flex-wrap: wrap;
  }

  @include response-small {
    flex-direction: row;
  }
}

.otherOptions {
  width: 100%;

  & > * {
    width: 100%;
  }
}

.focusFirstButton {
  border: none;
  background: transparent;
  height: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
}

.divider {
  border-top: 1px solid $light-color2;
}
