.container {
  position: relative;
  height: min-content;

  width: 100%;
}

.hoverLayer {
  opacity: 0.9;
  overflow: hidden;

  background-color: rgb(151, 151, 151);
  color: white;

  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  border-radius: 5px;
  border: 1px solid #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: opacity 0.2s ease-in-out;

  z-index: 2;
}
