@import 'assets/scss/variables.module';

.container {
  @include flex-even;

  gap: 0;
  margin: 0;

  & > * {
    margin: 0;
  }

  height: 25px;
  width: 100%;
  border-radius: 5px;

  background-color: transparent;

  container-type: inline-size;
}

.price {
  transition: opacity 0.2s ease;
  font-weight: bold;
  color: $color;
  white-space: nowrap;

  &:hover {
    opacity: 0.6;
    color: $archidekt-orange;
  }
}

@container (max-width: 130px) {
  .price {
    width: unset;
  }

  .priceTwo {
    display: none;
  }
}
