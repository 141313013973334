@import 'assets/scss/variables.module';

.container {
  max-width: 900px;
}

.content {
  column-count: 2;

  & > div {
    page-break-inside: avoid; /* Theoretically FF 20+ */
    break-inside: avoid-column; /* Chrome, Safari, IE 11 */
    margin-bottom: 1rem;
  }

  @include response-medium {
    column-count: unset;
  }
}

.keybindContainer {
  @include flex-start;
}

.iconWrapper {
  display: flex;
  justify-content: flex-end;
  width: 145px;
}

.keybind {
  font-weight: bold;
  white-space: nowrap;

  color: $code-font-color;
  box-shadow: $generic-box-shadow;
  background: $light-background2;

  border-radius: 3px;

  padding: 0.1rem 10px;

  min-width: 33px;
  text-align: center;
}

.description {
  margin-left: 10px;
}
