@import 'assets/scss/variables.module.scss';
@import '../shared.module.scss';

.container {
  @include searchTab;

  color: $color;

  height: 100%;

  width: 100%;
  max-width: 100% !important;
  overflow: auto;
  padding: 2rem calc((100% - 900px) / 2);

  @include response-medium-small {
    padding: 1rem;
  }

  @include response-small {
    padding-bottom: 55px;
  }
}

.records {
  @include flex-start;

  width: 100%;
  max-width: 1350px;

  flex-direction: column;
  align-items: flex-start;

  padding: 0 20px;

  gap: 15px;
}

.back {
  @include absolute-position-buttons;

  top: 1rem;
  left: 0;
}

.historyEntryContainer {
  color: $color;
  border: none;
  background-color: $light-background;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  cursor: pointer;
  border-left: 4px solid $archidekt-orange;

  transition: transform 0.15s ease-in-out;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;

  flex-wrap: wrap;
  box-shadow: $generic-box-shadow;

  &:hover {
    transform: scale(1.02);
  }
}

.iconContainer {
  min-width: 150px;
  flex: 0.2;
  order: 1;
}

body:global(.dark-mode) .iconContainer {
  img {
    filter: invert(1);
  }
}

.queryContainer {
  flex: 0.7;
  min-width: 150px;
  text-align: left;
  font-family: monospace;
  color: $code-font-color;
  background-color: $light-background2;
  padding: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  order: 2;
  @include response-small {
    order: 4;
    flex: 1 1 100%;
  }
}
body:global(.search-v2-locked) .queryContainer {
  order: 4;
  flex: 1 1 100%;
}

.timeContainer {
  text-align: right;
  padding-left: 10px;
  flex: 0.1;
  min-width: 100px;
  color: $light-color;
  order: 3;
}
