@import 'assets/scss/variables.module.scss';

$size: 15px;

.ownedCollectionDot {
  height: $size;
  width: $size;

  border-radius: 50%;

  border: 1px solid $light-color2;

  background-color: $quantity-blue;
}

.greenDot {
  background-color: $quantity-green;
}
