@import 'assets/scss/variables.module.scss';

.container {
  background-color: $light-background;
  border: 1px solid $light-color2;
  padding: 1rem;
  border-radius: 0.5rem;

  container-type: inline-size;
}

.spacer {
  margin-bottom: 1rem;
}

.infoBlock {
  border: 1px solid $light-color2;
  background-color: $light-background;

  padding: 1rem;
  border-radius: 0.5rem;

  color: $color;
}

.controls {
  @extend .spacer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.cards {
  @extend .spacer;

  padding: 0 1rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  justify-items: center;
  gap: 1.5rem;
}

.card {
  max-width: 225px;
}

.comboInfo {
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 1fr 1fr;
}

@container (max-width: 1000px) and (min-width: 600px) {
  .comboInfo {
    grid-template-columns: 1fr 1fr;

    & > *:first-child {
      grid-area: 1 / 1 / 2 / 3;
    }
  }
}

@container (max-width: 600px) {
  .comboInfo {
    grid-template-columns: 1fr;
  }
}

.spellbookLogo {
  height: 20px;
  filter: brightness(2); // Forces the logo to be white
}

.status {
  color: $color;

  display: flex;
  gap: 0.5rem;

  font-size: 16px;
  font-weight: bold;

  i {
    margin-right: 0 !important;
  }
}

.green {
  color: $button-green;
}

.red {
  color: $button-red;
}

.extraRequirements {
  border: 1px solid $light-color2;
  border-radius: 8px;
  padding: 0.5rem;

  width: 100%;
  aspect-ratio: 5/7;
}

.extraRequirementsList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  button {
    background-color: transparent;
    border: none;
    color: $color;
    transition: color 0.2s;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover:enabled {
      cursor: pointer;

      & > :last-child {
        color: $archidekt-orange;
        text-decoration: underline;
      }
    }
  }
}
