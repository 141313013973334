.controls {
  display: flex;
  justify-content: flex-end;

  margin: 1rem 0;
}

.flags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
