@import 'assets/scss/variables.module.scss';

.container {
  min-height: 200px;
  max-height: 398px;
  overflow: auto;

  border-radius: 5px;
  border: 2px solid $light-color2;
  background-color: $background-color;

  overflow: auto;

  padding: 0.5rem 0;

  color: $color;

  @include response-small {
    max-height: unset;
  }
}

.group {
  margin-bottom: 1.5rem;
}

.header {
  font-size: 1.125rem;
  font-weight: bold;

  margin-bottom: 0.5rem;

  padding: 0 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  padding: 0.25rem 1.5rem;

  transition: background-color 0.2s ease-in-out;

  @include response-small {
    flex-wrap: wrap;
  }
}

.details {
  padding: 0.25rem 3rem;
}

.tag {
  padding-left: 1rem;
}

.loader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  padding: 0.25rem 1.5rem;

  pointer-events: none;
}

.cardInfo {
  display: flex;
  flex: 1;
  gap: 0.5rem;

  border: none;
  background: transparent;
  color: $color;

  &:hover:enabled {
    cursor: pointer;
  }
}

.select {
  display: flex;
  gap: 0.5rem;
  justify-content: center;

  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: 1px sold $light-background2;
  padding: 0.25rem 1rem;

  transition: filter 0.2s ease-in-out;

  &:hover:enabled {
    cursor: pointer;
    filter: brightness(1.2);
  }

  &:disabled {
    opacity: 0.5;
  }

  @include response-small {
    width: 100%;
  }
}

.create {
  background-color: $button-green;
}

.update {
  background-color: $button-blue;
}

.stripes {
  @include stripes;
}

.cardName {
  white-space: nowrap;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noMatches {
  text-align: center;
  color: $light-color;
  font-weight: bold;
  display: flex;
  justify-content: center;
  gap: 0.2rem;
}
