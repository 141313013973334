@import 'assets/scss/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 1rem;

  background-color: $light-background;
  border: 1px solid $light-color2;
  border-radius: 5px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  button {
    background-color: $button-green;
    color: white;
    font-weight: bold;

    padding: 0.75rem 1rem;
    border: none;
    border-radius: 5px;

    display: flex;
    gap: 0.5rem;

    height: 39px;
    white-space: nowrap;

    transition: filter 0.2s ease-in-out;

    &:hover:enabled {
      cursor: pointer;

      filter: brightness(1.2);
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.deckSearchDropdown {
  border-radius: 5px;

  border: 1px solid $light-color2;
  background-color: $light-background;
  color: $color;

  padding: 0.75rem 1rem;

  transition: background-color 0.2s ease-in-out;

  -webkit-outer-spin-button,
  -webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  &:hover:enabled,
  &:focus:enabled {
    cursor: text;
    background-color: $light-background2;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.categoryDropdown {
  width: 100%;

  & > div {
    width: 100%;
  }
}
