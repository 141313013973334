@import './mixins/scrollbar.module.scss';
@include scrollbar;

html {
  height: 100%;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  -webkit-tap-highlight-color: transparent;

  // Can't be on all the time because Edge is a terrible browser </3
  // scrollbar-gutter: stable;
}

button {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

#__next {
  min-height: 100%;
}

// Spreadsheet view _needs_ the page to have width: max-content just below the body for left stickied elements
body.deck-overscroll-view {
  #__next {
    width: max-content;
  }
}

// Edge has a bug with scrollbar-gutter stable interacting with fixed elements
.corrected-scrollbar-gutter {
  scrollbar-gutter: stable;
}

body {
  @include scrollbar;

  overflow: overlay;
  height: 100vh; // Fixes bug on chrome android where the body wasn't taking up the whole container
  transition: background-color 0.25s ease-in;

  --background-color: #f9fafb;
  --rgb-background: rgb(249, 250, 251);
  --light-background: #fafafa;
  --light-background2: #dedede;
  --light-background3: #c1c1c1;
  --alt-background: #dfdfdf;
  --color: #383838;
  --secondary-text-color: #727272;
  --light-color: #848484;
  --light-color2: #bababa;
  --light-color3: #d4d4d4;
  --rgb-color: rgb(56, 56, 56);
  --table-border-color: rgb(219, 219, 219);
  --table-alternate-color: #d8d8d8;
  --link-color: #4183c4;
  --framing-color: rgb(48, 48, 48);
  --rgb-framing-color: rgb(48, 48, 48);
  --card-border: #5b5b5b;
  --input-border-color: rgba(34, 36, 38, 0.15);

  --secondary-color: rgb(255, 202, 132);
  --tertiary-color: rgb(166, 166, 166);

  --table-selected: #eff4fd;
  --table-drop: #c2d8ff;
  --overlay-background: rgba(217, 217, 217, 0.95);
  --scrollbar-thumb: rgba(0, 0, 0, 0.5);
  --scrollbar-thumb-hover: #555;

  --card-button-green: #075e1d;
  --card-button-red: #ad101c;
  --card-button-blue: #0a3575;

  background-color: var(--background-color);

  --archidekt-orange-transparent: rgba(250, 137, 13, 0.3);
}

body.dark-mode {
  @include scrollbar;

  --background-color: #181818;
  --rgb-background: rgb(36, 34, 33);
  --light-background: #232323;
  --light-background2: #383838;
  --light-background3: #4b4b4b;
  --alt-background: #2b2b2b;
  --color: #e3e3e3;
  --secondary-text-color: #a8a8a8;
  --light-color: #8b8b8b;
  --light-color2: #5f5f5f;
  --light-color3: #323232;
  --rgb-color: rgb(227, 227, 227);
  --table-border-color: rgba(76, 76, 76);
  --table-alternate-color: #2b2b2b;
  --link-color: #73a8dc;
  --framing-color: rgb(14, 14, 14);
  --rgb-framing-color: rgb(14, 14, 14);
  --card-border: #525252;
  --input-border-color: rgba(219, 230, 241, 0.07);

  --secondary-color: #422d00;
  --tertiary-color: #151515;

  --table-selected: #333438;
  --table-drop: #4e586a;
  --overlay-background: rgba(22, 22, 22, 0.95);
  --scrollbar-thumb: rgba(150, 150, 150, 0.5);
  --scrollbar-thumb-hover: rgb(197, 197, 197);

  --card-button-green: #c3ffd4;
  --card-button-red: #ffc6c6;
  --card-button-blue: #cbe5ff;

  background-color: var(--background-color);
}

// Fixing semantic's weird margin on icons
i.icons .icon:first-child {
  margin: 0 !important;
}

body.no-scrolling {
  overflow: hidden !important; // Firefox applying overflow auto as an inline style for some reason
}

body.context-menu-open {
  overflow: hidden !important;
}

.adheasion_mobile_wrapper {
  max-height: 50px;
  overflow: hidden;
}
