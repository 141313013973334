@import 'assets/scss/variables.module.scss';

.radio {
  width: 100%;

  display: flex;
  gap: 1rem;

  margin-bottom: 1rem;

  span {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    font-weight: bold;
  }
}

.horizontalRule {
  border-bottom: 2px solid $light-color2;
  margin: 1rem 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  min-height: 200px;
  max-height: 330px;

  overflow: visible;
  border-radius: 5px;
  border: 2px solid $light-color2;
  background-color: $background-color;

  padding: 1rem;
}

.primaryInput {
  & > label {
    @extend .headers;
  }

  & > * {
    margin: 0.25rem 0;
  }
}

.secondaryOptions {
  & > label {
    @extend .headers;
  }
}

.headers {
  font-weight: bold;
  font-size: 16px;
}

.editionLocks {
  display: flex;
  flex-direction: column;

  & > label {
    @extend .headers;
  }

  & > * {
    margin: 0.25rem 0;
  }

  margin-bottom: 1rem;
}
