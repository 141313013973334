@import 'assets/scss/variables.module.scss';

.categoryOptions {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;

  & > * {
    max-width: 285px;
  }

  @include response-medium {
    flex-wrap: wrap;
  }

  @include response-small {
    & > * {
      max-width: 100%;
    }
  }
}

.input {
  width: 100%;

  margin-top: 2rem;
  margin-bottom: 1rem;

  input {
    width: 100%;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
}

.personalCategoriesBlurb {
  width: 100%;
  padding: 1rem;
  background-color: $light-background2;
  border-radius: 5px;
  border: 1px solid $light-color2;
  margin-bottom: 2rem;
}
