@import 'assets/scss/variables.module';

.container {
  @include sticky-wide-table-page;

  color: white;
  text-align: center;
  background-color: $framing-color;
  flex-shrink: 0;
  padding: 20px;
  padding-bottom: 120px; // Extra on the bottom so it doesn't overlap with mobile bar (on deck)
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;

  .icon {
    font-size: 2rem;
  }
}

.deckPageOverride {
  @include sticky-wide-table-deck-page;
}

.notices {
  font-size: 75%;
}
