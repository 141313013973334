@import 'assets/scss/variables.module.scss';

.container {
  min-height: 200px;
  max-height: 330px;
  overflow: auto;

  border-radius: 5px;
  border: 2px solid $light-color2;

  background-color: $background-color;

  padding: 0.5rem 0;

  @include response-small {
    max-height: unset;
  }
}

.group {
  margin-bottom: 1.5rem;
}
.controls {
  display: flex;
  justify-content: space-between;

  margin-right: 0.25rem;
}

.optionsMenu {
  width: 235px;
}

.header {
  color: $color;

  font-size: 1.125rem;
  font-weight: bold;

  margin-bottom: 1rem;

  padding: 0 1rem;
}

.stripes {
  @include stripes;
}

.loader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  padding: 0.25rem 1.5rem;

  pointer-events: none;

  color: $color;
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  padding: 0.25rem 1.5rem;

  color: $light-color2;
}

.deckRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include response-small {
    flex-wrap: wrap;
  }
}

.leftContent {
  display: flex;
  flex-direction: column;
  max-width: 50%;

  padding: 0.25rem 1.5rem;
}

.deckName {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  font-weight: bold;
  color: $color;

  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;

  transition: filter 0.2s ease-in-out;

  &:hover:enabled {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

.deckFormat {
  font-size: 0.875rem;
  color: $light-color;
}

.colors {
  width: 100px;
  display: flex;
  justify-content: flex-end;
}

.rightRight {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  padding: 0.25rem 1.5rem;

  @include response-small {
    width: 100%;
  }
}

.deckLink {
  padding: 0 1.5rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: $color;
  font-weight: bold;

  transition: color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: $archidekt-orange;
  }
}

.currentDeck {
  @extend .deckLink;

  color: $light-color;

  pointer-events: none;
  width: 134px; // matching the actual links width
}

.details {
  color: $color;
  padding: 0 2rem;

  margin-bottom: 0.5rem;
}

.matchingHeader {
  font-weight: bold;
}

.cardInfo {
  padding: 0 0.5rem;
}

.cardInfoRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 0.5rem;
}

.chevron {
  transition: transform 0.2s ease-in-out;
}

.detailsOpen {
  transform: rotate(90deg);
}

.sumOfCards {
  @extend .container;

  min-height: unset;
  font-weight: bold;
  padding: 1rem;
}
