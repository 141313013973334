@import 'assets/scss/variables.module';

.container {
  position: fixed;

  bottom: 0;
  left: 0;

  width: 100%;
  display: flex;
  justify-content: center;

  z-index: 8; // Fixed ad index should always be overlay z-index + 1

  overflow: hidden;

  background-color: $overlay-background-color;
}

.adContainer {
  position: relative;
  display: flex;

  justify-content: center;
  align-items: center;

  width: 728px;
  height: 90px;

  @include response-small {
    height: 50px;

    border-top: 1px solid $table-border-color;
    background-color: $framing-color;
  }

  @include response-x-large {
    width: 100%;
  }

  a {
    color: $color;

    @include response-small {
      color: white;
    }
  }

  i {
    color: rgb(255, 66, 77);
  }
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  cursor: pointer;
  background: transparent;

  i {
    margin-right: 0 !important;
  }

  @include response-small {
    display: none;
  }

  @media (min-height: 700px) {
    display: none;
  }
}

.mobileAdheasion {
  display: none;

  @include response-small {
    display: unset;
    position: absolute;

    height: 100%;
    max-width: 100%;

    img {
      height: 100%;
      max-width: 100%;

      background-color: $background-color;
    }
  }
}
