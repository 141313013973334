@import 'assets/scss/variables.module';

.container {
  margin-top: 2rem;
}

.options {
  margin-top: 1rem;

  & > * {
    margin-bottom: 0.5rem;
  }
}

.label {
  font-weight: bold;
  display: block;

  margin-bottom: 5px;
}
