@import 'assets/scss/variables.module';

.option {
  padding: 0;

  button {
    color: $color;
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;

    padding: 0.5rem;
    padding-left: 15px;

    &:hover:enabled {
      cursor: pointer;
    }
  }
}
