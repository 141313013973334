@import 'assets/scss/variables.module.scss';

.container {
  display: flex;
  gap: 1rem;

  position: relative;

  label {
    position: absolute;

    top: -1.5rem;

    font-weight: bold;
    font-size: 0.85rem;
  }
}

.asthetics {
  padding: 0.75rem 1rem;

  color: $color;
  background-color: $light-background;
  border: 1px solid $light-color2;

  border-radius: 5px;
}

.dropdown {
  @extend .asthetics;

  width: 100%;
}

.dropdownWrapper {
  width: 100%;
}

.allEditions {
  @extend .asthetics;

  white-space: nowrap;
  font-weight: bold;

  display: flex;

  &:hover:enabled {
    cursor: pointer;
  }
}

.mobile {
  display: none;

  @include response-small {
    display: block;
  }
}

.desktop {
  display: block;

  @include response-small {
    display: none;
  }
}
