.container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  gap: 0.5rem;
  font-size: 18px;
}

.blueskyFontAwesomeAdjustment {
  display: flex;

  svg {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
  }
}
