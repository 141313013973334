@import 'assets/scss/variables.module.scss';

.button {
  background: transparent;
  border: none;
  color: $color;
  padding: 0;

  font-weight: bold;

  transition: filter 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

.container {
  max-width: 900px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.explanationBlock {
  background-color: $background-color;
  color: $color;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid $light-color2;

  width: 100%;

  a {
    color: $archidekt-orange;
    font-weight: bold;
  }
}
