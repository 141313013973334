@import 'assets/scss/variables.module';

@mixin size {
  max-width: 1250px;
  margin: auto;

  transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;

  @include response-x-large {
    max-width: 1000px;
  }

  @include response-large {
    max-width: 750px;
  }

  @include response-medium-small {
    max-width: 500px;

    padding: 0px;
  }
}

@mixin searchTab {
  @include size;

  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin absolute-position-buttons {
  position: absolute;

  background: transparent;
  color: $color;

  padding: 0.25rem 1rem;

  border: none;

  font-size: 2rem;
  font-weight: bold;

  transition: transform 0.15s ease-in-out;

  border-radius: 3px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover:enabled {
    cursor: pointer;

    transform: scale(1.1);
  }

  &:disabled {
    opacity: 0.4;
  }

  i {
    margin: 0;
  }

  span {
    font-size: 12px;
    margin-top: 0.1em;
  }
}

.backButton {
  @include absolute-position-buttons;

  position: fixed;
  top: 1rem;
  left: 1rem;

  z-index: 1;

  @include response-medium-small {
    top: 0.5rem;
    left: 0.5rem;
  }
}
