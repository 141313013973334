@import 'assets/scss/variables.module';

.container {
  @include flex-start;
  flex-direction: column;

  margin: 0;

  height: 100vh;
  width: 100vw;

  max-height: 100vh;
  max-width: 100%;

  min-height: 1000px;

  position: relative;
  overflow: hidden;

  background-color: #f0f0f0;

  background-image: linear-gradient(rgba($archidekt-orange, 0.15) 2px, transparent 2px),
    linear-gradient(90deg, rgba($archidekt-orange, 0.2) 2px, transparent 2px),
    linear-gradient(rgba($archidekt-orange, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba($archidekt-orange, 0.15) 1px, transparent 1px);

  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;

  transition: background-color 0.2s ease-in;
}

body:global(.dark-mode) .container {
  background-color: #1b1a18;

  background-image: linear-gradient(rgba($archidekt-orange, 0.08) 2px, transparent 2px),
    linear-gradient(90deg, rgba($archidekt-orange, 0.04) 4px, transparent 3px),
    linear-gradient(rgba($archidekt-orange, 0.03) 1px, transparent 1px),
    linear-gradient(90deg, rgba($archidekt-orange, 0.06) 1px, transparent 1px);
}

.sketch {
  position: absolute;
  user-select: none;
  pointer-events: none;
  z-index: 0;
}

body:global(.dark-mode) .sketch {
  filter: invert(0.95);
}

.content {
  width: 100%;
  max-width: 500px;

  margin-top: 200px;

  padding: 1rem;

  @include response-small {
    margin-top: 100px;
  }
}

.open {
  pointer-events: none;
}

.form {
  div {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
    }

    margin-bottom: 10px;
  }

  button {
    width: 100%;
  }

  margin-bottom: 10px;
}

.rememberMe {
  width: 100%;
  margin: 10px 0;

  label {
    color: $color !important;
    transform: filter 0.2s ease;

    &:hover,
    :focus {
      filter: brightness(1.1);
    }
  }
}

.loading {
  display: flex;
  gap: 5px;
  justify-content: center;
  width: 100%;
  height: 15px;
}

.extras {
  button {
    color: $archidekt-orange;
    background: none;
    border: none;
    font-weight: bold;

    transition: filter 0.2s ease;

    &:hover:enabled {
      cursor: pointer;
      filter: brightness(1.2);
    }
  }

  margin-bottom: 5px;
}

.branding {
  color: $color;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;

  img {
    width: 100px;
    margin-bottom: 10px;
  }
}

body:global(.dark-mode) .branding {
  img {
    filter: invert(1);
  }
}

.gradientMask {
  position: absolute;

  width: 100%;
  height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;

  transition: background 0.25s ease-in;
}

.topGradientMask {
  @extend .gradientMask;

  top: $toolbar-height;
  background: linear-gradient(180deg, $framing-color 0, rgba(161, 161, 161, 0) 78%);
}

.bottomGradientMask {
  @extend .gradientMask;

  height: 200px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(161, 161, 161, 0) 0%, $background-color 78%);
}
