@import 'assets/scss/variables.module';

.container {
  @include flex-center;

  flex-wrap: wrap;

  margin: 0;

  width: 100%;
}

.label {
  font-size: 16px;
  font-weight: bold;

  width: 85px;
}

.radio {
  @include flex-start;

  font-size: 16px;
  font-weight: bold;
}

.input {
  width: 50% !important;
}
