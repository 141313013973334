@import 'assets/scss/variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  transition: opacity 0.2s ease-in-out;

  background-color: $light-background;
  border-radius: 1rem;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

.stackHeader {
  background-color: $light-background;
  border-radius: 5px 5px 0 0;

  z-index: 1;
}

.cardStack {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  // Prevents the above stack from clipping
  background-color: $light-background;
  margin-top: -1px;

  width: 100%;

  // padding-bottom: 2rem;

  & > div {
    width: 100%;

    will-change: transform;

    transition: transform 0.3s ease-in-out;
    transition-delay: 0.2s;

    &:hover ~ div,
    &:focus-within ~ div {
      transform: translateY(90%);
    }
  }
}

.maybeStack {
  // Can we do anything to make this more clear?
}

.emptyStack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: bold;

  width: 100%;

  aspect-ratio: 5 / 7;

  border-radius: 1rem;
  background-color: $light-background3;
  border: 1px solid $light-color2;
}

.basicCard {
  width: 100%;
  min-width: unset;

  height: auto;
  min-height: unset;
}

.cardImage {
  overflow: hidden;
  position: unset;
}
