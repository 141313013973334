@import 'assets/scss/variables.module';

.container {
  @include flex-center;

  gap: 0.1rem;

  button {
    background: transparent;
    border: none;

    transition: opacity 0.1s ease, transform 0.1s ease;

    &:hover:enabled {
      cursor: pointer;

      transform: scale(1.1);
    }
  }
}

.radioContainer {
  @include flex-start;
}

.mainRadioLabel {
  font-weight: bold;

  font-size: 1.1rem;
}

.cursors {
  @include flex-start;

  & > * {
    cursor: pointer;
  }
}
