@import 'assets/scss/variables.module';

.container {
  @include sticky-wide-table-deck-page;
  position: relative;

  width: 100%;
}

.backgroundContainer {
  position: absolute;

  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100%;
}

.background {
  height: 100%;
  width: 100%;

  background-size: cover;
  background-position: 0% 30%;

  transition: background 1s ease-in-out;

  // This filter extends outside of it's parent, so you _must_ have overflow: hidden on parent (@see the above container)
  filter: blur(8px);
}

.header {
  color: #fff;
  font-size: 250%;
  position: absolute;
  bottom: 40px;
  left: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.gradientMask {
  position: absolute;

  width: 100%;
  height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  transition: background 0.25s ease-in;
}

.topGradientMask {
  @extend .gradientMask;

  top: 0;
  background: linear-gradient(180deg, $framing-color 0, rgba(161, 161, 161, 0) 75%);
}

.bottomGradientMask {
  @extend .gradientMask;

  height: 200px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(161, 161, 161, 0) 0%, $background-color 78%);
}
