@import 'assets/scss/variables.module.scss';

.container {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  user-select: none;
}

.colorSquare {
  display: block;
  height: 15px;
  width: 15px;
  border: 1px solid $light-color2;
}
