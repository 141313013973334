@import 'assets/scss/variables.module';

.container {
  @include flex-start;

  label {
    font-weight: 16px;
    font-weight: bold;
    white-space: nowrap;

    @include response-x-small {
      white-space: unset;
    }
  }

  button {
    background: transparent;
    border: none;

    transition: opacity 0.1s ease, transform 0.1s ease;

    &:hover:enabled {
      cursor: pointer;

      transform: scale(1.1);
    }
  }
}
