@import 'assets/scss/variables.module';

.container {
  overflow: visible;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  width: 100%;
  height: 100%;
}

.loadMore {
  width: 100%;

  margin: 1rem 0;

  display: flex;
  justify-content: center;
}
