@import 'assets/scss/variables.module';
@import '../commonLayout.module.scss';

.container {
  @extend .common-container;

  padding-left: 0.5rem; // Accounting for the stickied toolbar
  padding-bottom: 0; // Moving the bottom padding to the column container so the clip point for moving cards is at the bottom of the container

  @include response-small {
    padding: 1rem;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  max-width: 2300px;

  padding: 0.5rem 1rem;

  background-color: $light-background;
  border: 1px solid $input-border-color;
  border-radius: 5px 5px;

  z-index: 2; // Force the dropdown menu above the following sibling
}

.grid {
  // width: 100%;
  max-width: 100%;
  max-height: 100%;

  display: grid;
  gap: 0.5rem;

  grid-auto-flow: column;

  overflow-x: auto;
  overflow-y: hidden;

  // If masonry layout actually workedN
  // grid-template-rows: masonry;
  // masonry-auto-flow: next;
}

.maybeStacks {
  max-width: 200px;

  grid-template-columns: repeat(1, 1fr) !important;
}

.column {
  border-radius: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.emptyStack {
  padding: 2rem 0;

  display: flex;
  justify-content: center;
  font-weight: bold;
}

.draggingOver {
  background-color: $light-background2;
}
.addColumnButton {
  border-radius: 5px 0 0 5px;
}

.extraOptionsTrigger {
  padding: 0.75rem 1rem;
  border-radius: 0 5px 5px 0;

  i {
    margin: 0 !important;
  }
}

.extraOptionsMenu {
  width: 215px;
}

.removeColumnButtonOption {
  background-color: $button-red;
  color: white !important;
  font-weight: bold;
  transition: filter 0.1s ease;

  &:hover:enabled {
    background-color: $button-red;
    filter: brightness(1.1);
  }
}

.removeColumnOption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disableMargins {
  max-width: 100%;
}
