@import 'assets/scss/variables.module';

.queryableContainer {
  container-type: inline-size;
  width: 100%;
}

.container {
  display: grid;
  position: relative;

  margin-top: 1rem;

  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-items: center;
  align-content: center;

  gap: 1.5rem;
  padding: 0 12px; // Needed with our buttons that are wider than the card
}

.lowerLoader {
  margin: 3rem 0;
}

@container (max-width: 1600px) {
  .container {
    grid-template-columns: repeat(6, 1fr); // Will be overriden by the container query
  }
}

@container (max-width: 1400px) {
  .container {
    grid-template-columns: repeat(5, 1fr); // Will be overriden by the container query
  }
}

@container (max-width: 1000px) {
  .container {
    grid-template-columns: repeat(4, 1fr); // Will be overriden by the container query
  }
}

@container (max-width: 800px) {
  .container {
    grid-template-columns: repeat(3, 1fr); // Will be overriden by the container query
  }
}

@container (max-width: 600px) {
  .container {
    grid-template-columns: repeat(2, 1fr); // Will be overriden by the container query
  }
}

@container (max-width: 335px) {
  .container {
    grid-template-columns: repeat(1, 1fr); // Will be overriden by the container query

    max-width: 225px;
    margin: 0 auto;
    justify-items: center;
  }
}
