@import 'assets/scss/variables.module';

.container {
  width: 100%;
}

.stickyForm {
  position: sticky;
  top: -65px;

  z-index: 2;

  background-color: $overlay-background-color;

  @include response-small {
    top: -1rem;
  }
}

.info {
  font-style: italic;
  color: $light-color;
}

.form {
  @include flex-start;

  gap: 0.5rem;
  margin-top: 0;
}

.input {
  flex: 1;
}

.row {
  @include flex-around;

  color: $color;
  font-weight: bold;
}

.scryfallLink {
  text-wrap: nowrap;
}

.hideOnMobile {
  @include response-small {
    display: none;
  }
}
